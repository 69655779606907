<script setup lang="ts">
import Resizer from "../Resizer.vue"
import { useStorage } from "@vueuse/core"
import { reactive, watchEffect } from "vue"

const props = withDefaults(defineProps<{ fluid?: boolean }>(), {
  fluid: false,
})
const stored = useStorage("ps-layout-published-horizontal", {
  grid: [200, 450],
  subGrid: [300, 300],
})

const grid = reactive(stored.value.grid)
const subGrid = reactive(stored.value.subGrid)

watchEffect(() => {
  stored.value = { grid, subGrid }
})

const onGridResize = (index: number, diffs: { x: number; y: number }) => {
  const newSize = grid[index - 1] + diffs.x
  if (newSize > 100) {
    grid[index - 1] += diffs.x
  }
}
const onSubGridResize = (index: number, diffs: { x: number; y: number }) => {
  const newSize = subGrid[index - 1] + diffs.y
  if (newSize > 100) {
    subGrid[index - 1] += diffs.y
  }
}
</script>

<template>
  <div class="layout" :class="{ fluid }">
    <div :style="{ flexBasis: grid[0] + 'px' }">
      <slot name="left" />
    </div>
    <Resizer v-if="fluid" dir="vertical" @resize="onGridResize(1, $event)" />
    <div class="sub-layout" :style="{ flexBasis: grid[0] + 'px' }">
      <section id="popups-panel-horizontal" class="popups"></section>
      <div class="main" :style="{ flexBasis: subGrid[0] + 'px' }">
        <slot name="main" />
      </div>
      <Resizer
        v-if="fluid"
        dir="horizontal"
        @resize="onSubGridResize(1, $event)"
      />
      <div class="preview" :style="{ flexBasis: subGrid[1] + 'px' }">
        <section id="popups-preview-horizontal" class="popups"></section>
        <slot name="preview" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.layout {
  display: flex;
  height: 100%;
  max-height: 100%;
  flex-direction: row;

  > div {
    min-width: 100px;

    &:last-child {
      flex-grow: 1;
    }
  }
}
.sub-layout {
  display: flex;
  min-width: 100px;
  flex-direction: column;
  position: relative;

  > div {
    min-height: 100px;

    &:last-child {
      flex-grow: 1;
    }
  }
}

.main,
.preview {
  overflow: hidden;
  min-height: 100px;
  position: relative;
}
</style>
