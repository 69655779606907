<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PsDropdownIcon from "@/ContextTab/components/UI/PsDropdownIcon.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import usePresetButton from "./composables/usePresetButton"

defineProps<{ open: boolean; preset?: any }>()

const emit = defineEmits(["click"])
const { size, bracket } = usePresetButton()
</script>

<template>
  <PsFilterButton
    :tooltip="preset ? `Выбранный пресет: ${preset.name}` : 'Пресеты'"
    :color="bracket === 'xs' ? 'default' : null"
    showTooltip="always"
    size="sm"
    name="Пресеты"
    class="presets-button"
    @click="emit('click')"
  >
    <template #prefix>
      <PbIcon v-if="preset" name="folder-filled" size="14" />
      <PbIcon v-else name="folder" size="14" />
    </template>
    <template #suffix>
      <PsDropdownIcon :open="open" />
    </template>
    <template #xs>
      <template v-if="preset">
        <span class="preset-name">{{ preset.name }}…</span>
      </template>
      <template v-else>Выбрать пресет…</template>
    </template>
    <template #sm></template>
    <template #lg>
      <template v-if="preset">
        <span class="preset-name">{{ preset.name }}</span>
      </template>
      <template v-else>Пресеты</template>
    </template>
  </PsFilterButton>
</template>

<style scoped lang="postcss">
.preset-name {
  display: inline-block;
  max-width: 18ch;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
