import { useStoreAsync } from "@/ContextTab/useStore"
import deepToRaw from "@/utils/deepToRaw"
import type { CodesStore } from "@/ContextApp/stores/codes"

export default async function usePasssCodes() {
  const codesStore = await useStoreAsync<CodesStore>("codes", { shared: true })

  return async (legacyApp: any) => {
    legacyApp.$store.commit(
      "news/codes/CODES_SET_ALL",
      deepToRaw(codesStore.data.allCodesById)
    )
    legacyApp.$store.commit("news/codes/CODES_SET_PROFILE", {
      typeName: "NEWS_OR_ISSUE",
      codes: deepToRaw(codesStore.data.newsCodesById),
    })
    legacyApp.$store.commit("news/codes/CODES_SET_PROFILE", {
      typeName: "PUBLISH_NEWS",
      codes: deepToRaw(codesStore.data.publicationCodesById),
    })
    legacyApp.$store.commit("news/codes/CODES_INIT", 2)
  }
}
