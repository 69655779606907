<script setup lang="ts">
import PbDateWidget from "@/ContextTab/components/Date/DateWidget.vue"
import PsNewsTypeButton from "@/ContextTab/components/UI/Button/PsNewsTypeButton.vue"

interface Props {
  type: "newsItem" | "newsbreak" | "asoiNote" | "thumb"
  entry: object
  isCurrent?: boolean
  hasHorizontalLine?: boolean
  isExpress?: boolean
}

withDefaults(defineProps<Props>(), {
  isCurrent: false,
  hasHorizontalLine: true,
  isExpress: false,
})

const emit = defineEmits(["click"])
</script>

<template>
  <div v-if="type === 'thumb'" class="relations-entry thumb" />
  <div
    v-else
    class="relations-entry"
    :class="{
      type,
      current: isCurrent,
      horizontal: hasHorizontalLine,
      revoked: entry.is_revoked,
      express: isExpress,
    }"
  >
    <span class="relations-entry__vertical" />
    <div class="relations-entry__header">
      <PsNewsTypeButton
        v-if="['newsItem', 'asoiNote'].includes(type)"
        class="relations-entry__icon"
        :typeId="entry.type_id"
        :disabled="true"
        data-test-selector="entry-type-button"
        :data-test-attrs="`type__${entry.type_id}`"
      />
      <div v-if="type === 'newsbreak'" class="relations-entry__source">
        {{ entry.source }}
      </div>
      <PbDateWidget class="relations-entry__date" :date="entry.date" />
    </div>
    <div class="relations-entry__body">
      <span @click="emit('click', entry)">{{ entry.body }}</span>
    </div>
    <div class="relations-entry__footer" :title="entry.meta">
      {{ entry.meta }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.relations-entry {
  position: relative;
  margin-bottom: 15px;
  padding: 5px 0;

  &:last-child {
    margin-bottom: 0;

    .relations-entry__vertical {
      display: none;
    }
  }

  &.current {
    background: #fff9aa;
  }

  &.thumb {
    height: 106px;
  }
}

.relations-entry__vertical {
  position: absolute;
  top: 13px;
  left: 2px;
  z-index: 1;
  height: calc(100% + 13px);
  width: 1px;
  background: #a5a5a5;
}

.relations-entry__header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  line-height: 1rem;
  font-size: 0.75rem;
  color: #848484;

  &:before {
    content: "";
    flex-grow: 0;
    flex-shrink: 0;
    width: 5px;
    height: 5px;
    background: #a5a5a5;
    border-radius: 50%;
  }

  .horizontal &:after {
    content: "";
    flex-grow: 1;
    height: 1px;
    background: #a5a5a5;
  }
}

.relations-entry__source {
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px 0 10px;
}

.relations-entry__icon {
  flex-grow: 0;
  height: 1rem;
}

.relations-entry__date {
  flex-grow: 0;
  padding-right: 5px;
}

.relations-entry__body {
  overflow: hidden;
  height: 54px;
  padding: 0 15px;
  line-height: 1.125rem;
  font-size: 1rem;

  span:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.revoked {
  background: #fdf2f4;
  .relations-entry__body {
    text-decoration: line-through;
  }
}

.express {
  .relations-entry__body {
    color: #f22;
  }
}

.relations-entry__footer {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 5px;
  padding: 0 15px;
  line-height: 1rem;
  font-size: 0.75rem;
  color: #848484;
}
</style>
