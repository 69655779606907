<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import WithInplaceMessage from "@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue"
import { IForm, IFormGroup, IInput, IFormError } from "@inkline/inkline"
import { computed, ref } from "vue"
import useWiredForm from "@/ContextTab/composables/useWiredForm"
import { useStore } from "@/ContextTab/useStore"
import type { AuthStore } from "@/ContextApp/stores/auth"

const emit = defineEmits(["reset"])

const store = useStore<AuthStore>("auth", {
  shared: true,
})

const busy = ref(false)

const storeError = computed(() => store?.data.error)

const formConfig = {
  username: {
    validators: [
      { name: "required", message: "Введите электронную почту" },
      { name: "email", message: "Введите корректный адрес электронной почты" },
      {
        name: "custom",
        key: "api",
        fallbackMessage: "Пользователь не найден",
      },
    ],
  },
  password: {
    validators: [
      {
        name: "custom",
        key: "api",
        fallbackMessage: "Неверный пароль",
      },
    ],
  },
  keepLoggedIn: {
    value: false,
  },
}

const handleLogin = async () => {
  busy.value = true
  await store.authorize({
    username: form.value.username,
    password: form.value.password,
  })
  busy.value = false
}

const { schema, form, submit } = useWiredForm<{
  username: string
  password: string
  keepLoggedIn: boolean
}>(formConfig, storeError, handleLogin)

const check = (event: boolean) => (schema.value.check = event)

const unmappedErrors = computed(() => {
  return storeError.value?.errors?.filter(error => !error?.source?.pointer) ?? []
})
</script>

<template>
  <div class="auth">
    <IForm v-model="schema" class="auth-form" :loading="busy" @submit="submit">
      <WithInplaceMessage :loading="busy">
        <div class="wrapper">
          <h1 class="title">
            Авторизация
            <small 
              v-for="error in unmappedErrors" 
              :key="error.code" 
              class="ps-form-unmapped-error"
            >
              {{ error.title }}
            </small>
          </h1>
          <IFormGroup size="lg" class="ps-form-group">
            <IInput v-model="schema.username.value" name="username" type="text" autocomplete="username"
              placeholder="Электронная почта" size="lg" />
            <IFormError for="username" :visible="['invalid']" />
          </IFormGroup>
          <IFormGroup size="lg" class="ps-form-group">
            <IInput v-model="schema.password.value" name="password" type="password" placeholder="Пароль"
              autocomplete="current-password" size="lg" :showPasswordToggle="true" />
            <IFormError for="password" :visible="['invalid']" />
          </IFormGroup>
          <IFormGroup inline size="lg" class="ps-form-group">
            <PsCheckbox name="keepLoggedIn" size="lg" :checked="schema.keepLoggedIn.value" @update:checked="check">
              <label class="ps-form-group__label">Запомнить меня</label>
            </PsCheckbox>
          </IFormGroup>
          <IFormGroup size="lg">
            <PsButton block size="lg" type="submit" color="primary"
              :disabled="schema.prisine || (schema.touched && schema.invalid)">
              Войти в систему
            </PsButton>
          </IFormGroup>

        </div>
      </WithInplaceMessage>
    </IForm>
    <div class="optional-link">
      Если вы забыли пароль,{{ " " }}
      <a href="javascript:;" @click="emit('reset')">восстановите его</a>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
@import "../styles/auth.pcss";
</style>
