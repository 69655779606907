/* eslint-disable no-unused-vars, no-multiple-empty-lines */
// import { TEST_PORT } from '@/tests/apiMock/randomPort'
import { version } from "../../package.json"
import uiConfig from "./uiConfig"

// ###############################
// Настройки режима отладки
// ###############################
export const DEBUG = false

// ###############################
// Настройки окружения
// ###############################
export const IS_DEV = import.meta.env.DEV
export const VERSION = version
// export const SHOW_SWAGGER = import.meta.env.BUILD_ENV !== "production";
// export const SHOW_HACKER_INTERFACE = import.meta.env.BUILD_ENV !== "production";
// export const MESSAGE_HUB_DEBUG = import.meta.env.BUILD_ENV !== "production";

export const SHOW_SWAGGER = false
export const SHOW_HACKER_INTERFACE = false
export const MESSAGE_HUB_DEBUG = false

// ###############################
// Настройки Sentry
// ###############################
export const SENTRY_PUBLIC_KEY = "07c22769baf0422b8e86574a1cb718e8"

// ###############################
// Настройки подключения к серверу
// ###############################

let apiAddress = `https://${globalThis.location.host}/api/v1`
let adminPeriodicalApiAddress = `https://${globalThis.location.host}/api/admin-periodical/v1`
let gatewayAddress = `wss://${globalThis.location.host}/gateway/v1`
let sitePreviewAddress = `https://${globalThis.location.host}/site_preview/v1/`
let sentryAddress = `https://${SENTRY_PUBLIC_KEY}@${globalThis.location.host}/sentry/7`
let checkAddress = `https://${globalThis.location.host}/check`
let predictAddress = `https://${globalThis.location.host}/codes/v1`

const localAPI = "https://publishing/api/v1"
const localGateway = "wss://publishing/gateway/v1"
const localSitePreviewAddress = "https://publishing/site_preview/v1/"
const localCheckAddress = "https://publishing/check"
const localPredictAddress = "https://publishing/codes/v1"

const publicTestAPI = "https://test-publishing.interfax.ru/api/v1"
const publicTestAdminPeriodicalApi = `https://${globalThis.location.host}/api/admin-periodical/v1`
const publicTestGateway = "wss://test-publishing.interfax.ru/gateway/v1"
const publicTestSitePreviewAddress =
  "https://test-publishing.interfax.ru/site_preview/v1/"
const publicTestSentryAddress = `https://${SENTRY_PUBLIC_KEY}@test-publishing.interfax.ru/sentry/7`
const publicTestCheckAddress = "https://test-publishing.interfax.ru/check"
const publicTestPredictAddress = "https://test-publishing.interfax.ru/codes/v1"

const proxyTestAPI = `http://${globalThis.location.host}/api/v1`
const proxyTestAdminPeriodicalApi = `http://${globalThis.location.host}/api/admin-periodical/v1`
const proxyTestGateway = `ws://${globalThis.location.host}/gateway/v1`
const proxyTestSitePreviewAddress = `http://${globalThis.location.host}/site_preview/v1/`
const proxyTestSentryAddress = `http://${SENTRY_PUBLIC_KEY}@${globalThis.location.host}/sentry/7`
const proxyTestCheckAddress = `http://${globalThis.location.host}/check`
const proxyTestPredictAddress = `http://${globalThis.location.host}/codes/v1`

// const testingAPI = `http://127.0.0.1:${TEST_PORT}/api/v1`

const USE_TESTING_API = import.meta.env.USE_TESTING_API
const VITE_USE_DEV_PROXY = import.meta.env.VITE_USE_DEV_PROXY

if (IS_DEV) {
  apiAddress = localAPI
  gatewayAddress = localGateway
  sitePreviewAddress = localSitePreviewAddress
  checkAddress = localCheckAddress
  predictAddress = localPredictAddress
}

if (USE_TESTING_API) {
  apiAddress = publicTestAPI
  adminPeriodicalApiAddress = publicTestAdminPeriodicalApi
  gatewayAddress = publicTestGateway
  sitePreviewAddress = publicTestSitePreviewAddress
  sentryAddress = publicTestSentryAddress
  checkAddress = publicTestCheckAddress
  predictAddress = publicTestPredictAddress
}

if (VITE_USE_DEV_PROXY === "true") {
  apiAddress = proxyTestAPI
  adminPeriodicalApiAddress = proxyTestAdminPeriodicalApi
  gatewayAddress = proxyTestGateway
  sitePreviewAddress = proxyTestSitePreviewAddress
  sentryAddress = proxyTestSentryAddress
  checkAddress = proxyTestCheckAddress
  predictAddress = proxyTestPredictAddress
}

// if (import.meta.env.MODE === "test") {
//   apiAddress = testingAPI;
// }

export const API_ROOT = apiAddress
export const ADMIN_PERIODICAL_API_ROOT = adminPeriodicalApiAddress
export const GATEWAY_URL = gatewayAddress
export const CHECK_URL = checkAddress
export const SITE_PREVIEW = sitePreviewAddress
export const SENTRY_DSN = sentryAddress
export const PREDICT_URL = predictAddress
export const BASE_URL = globalThis.location.origin

export const gatewayQueueTimer = 1000 * 60 * 5
export const checkOnlineStatusTimer = 1000 * 60 * 2

// export const colors = uiConfig.colors;
// export const panelWidth = uiConfig.panelWidth;
export const schemes = uiConfig.schemes
// export const fonts = uiConfig.fonts;
// export const lineHeights = uiConfig.lineHeights;
// export const panelScaleFactors = uiConfig.panelScaleFactors;

export function getCurrentEnv() {
  if (globalThis.location.hostname === "localhost") {
    return "development"
  }
  if (globalThis.location.hostname === "test-publishing.interfax.ru") {
    return "testing"
  }
  if (globalThis.location.hostname === "publishing.interfax.ru") {
    return "production"
  }
  if (
    new RegExp(/.+\.test-publishing\.interfax\.ru/gim, "i").test(
      globalThis.location.hostname
    )
  ) {
    return "stage"
  }

  return "unknown"
}
