import { computed, type Ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { RefsStore } from "@/ContextApp/stores/references"
import type { NewsColorsStore } from "@/ContextApp/stores/news/colors"

export default function ({
  item,
  // codeColors,
  isSelected,
}: {
  item: Ref<any>
  // codeColors: Ref<Record<string, string>>
  isSelected: Ref<boolean>
}) {
  const refsStore = useStore<RefsStore>("references", {
    shared: true,
  })

  // const newsStore = useStore({
  //   storeKey: "news",
  //   shared: true,
  // })

  const colorsStore = useStore<NewsColorsStore>("colors", {
    shared: true,
  })

  // const bindings = {
  //   newsStatuses: refsStore.data.references?.News.Status,
  //   getIsUrgentVersionType,
  //   getIsNewsItemRevoked,
  //   getIsFeedCode,
  // }

  // const bindings_OLD = {
  // getIsUrgentVersionType:
  //   store.getters["news/versions/getIsUrgentVersionType"],
  // getIsNewsItemRevoked: store.getters["news/getIsNewsItemRevoked"],

  // getNewsItemCurrentVersion: store.getters["news/getNewsItemCurrentVersion"],
  // getNewsItemColorsByType: "TODO", //store.getters["news/getNewsItemColorsByType"],
  // getIsUserHasPublishingCodes:
  //   store.getters["news/codes/getIsUserHasPublishingCodes"],

  /**
   * [PUB-2859]
   * Устаревшая логика. Коды переопубликования теперь не ограничены префиксом 902.
   **/
  // republishManagerFilter: store.getters['news/codes/getIsPublicationCode'],

  // getSearchNewsVersion: store.getters["news/search/getSearchNewsVersion"],
  // newsStatuses: store.getters["references/newsStatuses"],
  // }

  const itemId = computed(() => item.value?.id)
  // const entry = computed(() => bindings.getNewsItemById(itemId.value))
  // const entry = item
  const version = computed(() => item.value.version)

  // const fontStyle = computed(() => {
  //   if (!highlightedCodes.value.length) return {}

  //   let color
  //   const lastElement = highlightedCodes.value.at(-1)

  //   if (codeColors.value[lastElement]) {
  //     color = codeColors.value[lastElement].value
  //   } else {
  //     color = bindings.allCodeItems[lastElement].track.reduce((acc, curr) => {
  //       if (acc) return acc
  //       return codeColors.value[curr]?.value || null
  //     }, null)
  //   }

  //   return { color }
  // })

  const highlightedCodes = computed(() => {
    return colorsStore.data?.colorsMap?.codes[itemId.value] || []
  })

  // const newsTypeButtonColor = computed(() => {
  //   if (isUrgent.value) return "#D0021B"
  //   return isSelected.value ? "#0069e4" : "#b3b3b3"
  // })

  // const versionType = inject("versionType", "regular")
  // const version = computed(() => {
  //   if (versionType === "search")
  //     return bindings.getSearchNewsVersion(itemId.value) || {}

  //   return bindings.getNewsItemCurrentVersion(itemId.value) || {}
  // })

  const isRevoked = computed(() => {
    return item.value.is_revoked
    // return bindings.getIsNewsItemRevoked(itemId.value)
  })
  const isUrgent = computed(() => {
    return version.value?.type_id < 3

    // if (version.value?.type_id == null) return false
    // return bindings.getIsUrgentVersionType(version.value.type_id)
  })
  const isReleased = computed(
    () =>
      refsStore.data.newsStatuses?.[item.value.version.status_id]?.name ===
      "PUBLISHED"
  )

  // переопубликование
  // const republishManager = reactive({ visible: false })
  // const showRepublishManager = async () => {
  //   if (!republishManager.visible) {
  //     /* Эмулируем клик, для того, чтобы у уже открытого попапа
  //      * другого элемента сработал vue-clickaway и он закрылся
  //      */
  //     document.body.click()
  //   }
  //   await nextTick()
  //   republishManager.visible = true
  // }
  // const republishAction = (...args) =>
  //   store.dispatch("news/republishUneditedNewsItem", ...args)

  return {
    // ...bindings,
    // entry,
    highlightedCodes,

    // republishManager,
    // showRepublishManager,
    // republishAction,

    // fontStyle,
    // highlightedCodes,
    // newsTypeButtonColor,
    // version,
    // versionType,
    isRevoked,
    isUrgent,
    isReleased,
  }
}
