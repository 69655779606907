import { nextTick, onMounted, onBeforeUnmount } from "vue"
import findInputElement from "./lib/findInputElement"
import type { Ref } from "vue"

export default function useAutoselect(el: Ref<HTMLElement | any>) {
  let cachedElement: HTMLElement | null = null

  onMounted(async () => {
    await nextTick()
    const element = findInputElement(el)
    if (element) {
      cachedElement = element
      element.select()
    }
  })

  onBeforeUnmount(() => {
    cachedElement?.blur()
  })
}
