export default async function usePassContextId() {
  return async (legacyApp: any) => {
    // @ts-expect-error contextId помещён в window для передачи контекста
    const contextId = window.contextId

    if (contextId) {
      legacyApp.$store.dispatch("setContextId", contextId)
    }
  }
}
