<script setup lang="ts">
import { IDropdown } from "@inkline/inkline"
import { IDropdownItem } from "@inkline/inkline"
import { computed } from "vue"

type DropdownTrigger = "click" | "hover" | "focus" | "manual"

type DropdownOption = {
  key: string | number
  label: string
  show?: boolean
  disabled?: boolean
}

interface Props {
  trigger?: DropdownTrigger
  options?: DropdownOption[]
  showArrow?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  trigger: "click",
  options: () => [],
  showArrow: false,
})

const emit = defineEmits(["select"])

const visibleOptions = computed(() =>
  props.options.filter(
    (option: DropdownOption) => option.show === undefined || option.show
  )
)
</script>

<template>
  <IDropdown :trigger="trigger" :arrow="showArrow">
    <slot />
    <template #body>
      <slot name="body">
        <IDropdownItem
          v-for="option in visibleOptions"
          :key="option.key"
          :disabled="option.disabled"
          @click="emit('select', option.key)"
          >{{ option.label }}</IDropdownItem
        >
      </slot>
    </template>
  </IDropdown>
</template>
