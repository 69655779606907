<script setup lang="ts">
import PbDateInput from "./DateInput.vue"
import PsDatePickerShortcuts from "./DatePickerShortcuts.vue"
import PbTimePicker from "./TimePicker.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import endOfToday from "date-fns/endOfToday"
import format from "date-fns/format"
import getYear from "date-fns/getYear"
import isAfter from "date-fns/isAfter"
import isSameDay from "date-fns/isSameDay"
import ru from "date-fns/locale/ru"
import parseISO from "date-fns/parseISO"
import { default as setDateValues } from "date-fns/set"
import startOfToday from "date-fns/startOfToday"
import startOfYear from "date-fns/startOfYear"
import subDays from "date-fns/subDays"
import { DatePicker as VDatePicker } from "v-calendar"
import { computed, reactive, ref, watch, onBeforeMount } from "vue"
import { DatePickerShortcuts } from "./types"
import type { Ref } from "vue"

type Mode = "single" | "range"
type DateInputType = "startDate" | "endDate" | "startTime" | "endTime"

const props = withDefaults(
  defineProps<{
    modelValue?: { start: string | null; end: string | null }
    mode?: Mode
    width?: number | string
    withTime?: boolean
  }>(),
  {
    mode: "range",
    width: 320,
    modelValue: () => ({
      start: null,
      end: null,
    }),
    withTime: true,
  }
)

const emit = defineEmits(["update:modelValue", "hide", "toggleSubmitDisabled"])

const dateRegex = /\d{2}\.\d{2}\.\d{4}/
const timeRegex = /\d{2}:\d{2}/
const getISODateFromString = (date: string): string =>
  date.split(".").reverse().join("-")

const datepicker: Ref<
  (Element & { moveBy: (pages: number) => Promise<boolean> }) | null
> = ref(null)

const timeRules = ref([
  {
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  {
    hours: 23,
    minutes: 59,
    seconds: 59,
  },
])

const internalValues = reactive({
  startDate: props.modelValue.start ? new Date(props.modelValue.start) : null,
  endDate: props.modelValue.end ? new Date(props.modelValue.end) : null,
  startTime: props.modelValue.start ? new Date(props.modelValue.start) : null,
  endTime: props.modelValue.end ? new Date(props.modelValue.end) : null,
})

const errors = reactive({
  startDate: false,
  endDate: false,
  startTime: false,
  endTime: false,
})

const range = computed({
  get: () => {
    const start = internalValues.startDate
      ? new Date(internalValues.startDate)
      : null
    if (start && internalValues.startTime) {
      start.setHours(
        internalValues.startTime.getHours(),
        internalValues.startTime.getMinutes(),
        internalValues.startTime.getSeconds()
      )
    }

    const end = internalValues.endDate ? new Date(internalValues.endDate) : null
    if (end && internalValues.endTime) {
      end.setHours(
        internalValues.endTime.getHours(),
        internalValues.endTime.getMinutes(),
        internalValues.endTime.getSeconds()
      )
    }
    return {
      start,
      end,
    }
  },

  set: value => {
    if (!value) {
      value = {
        start: null,
        end: null,
      }
    }
    internalValues.startDate = value?.start || null
    internalValues.endDate = value?.end || null
    const rangeChanged =
      !isSameDay(value.start, parseISO(props.modelValue.start)) ||
      !isSameDay(value.end, parseISO(props.modelValue.end))
    if (rangeChanged && value.start) {
      internalValues.startTime = setDateValues(value.start, {
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
    } else if (value.start) {
      internalValues.startTime = parseISO(props.modelValue.start)
    } else {
      internalValues.startTime = null
    }
    if (rangeChanged && value.end) {
      internalValues.endTime = setDateValues(value.end, {
        hours: 23,
        minutes: 59,
        seconds: 59,
      })
    } else if (value.end) {
      internalValues.endTime = parseISO(props.modelValue.end)
    } else {
      internalValues.endTime = null
    }
  },
})

const validateInputs = () => {
  if (!range.value.start || !range.value.end) return

  if (isSameDay(range.value.start, range.value.end)) {
    errors.startDate = false
    errors.endDate = false
    if (isAfter(range.value.start, range.value.end)) {
      errors.startTime = true
      errors.endTime = true
    } else {
      errors.startTime = false
      errors.endTime = false
    }
  } else {
    errors.startTime = false
    errors.endTime = false
    if (isAfter(range.value.start, range.value.end)) {
      errors.startDate = true
      errors.endDate = true
    } else {
      errors.startDate = false
      errors.endDate = false
    }
  }
}

const startDate = computed({
  get: () =>
    internalValues.startDate
      ? format(internalValues.startDate, "dd.MM.yyyy", { locale: ru })
      : "",
  set: value => {
    if (value === null) {
      internalValues.startDate = null
      return
    }
    if (dateRegex.test(value)) {
      const date = getISODateFromString(value)
      internalValues.startDate = parseISO(date)
    }
  },
})

const endDate = computed({
  get: () =>
    internalValues.endDate
      ? format(internalValues.endDate, "dd.MM.yyyy", { locale: ru })
      : "",
  set: value => {
    if (value === null) {
      internalValues.endDate = null
      return
    }
    if (dateRegex.test(value)) {
      const date = getISODateFromString(value)
      internalValues.endDate = parseISO(date)
    }
  },
})

const startTime = computed({
  get: () => {
    return internalValues.startTime
      ? format(internalValues.startTime, "HH:mm", { locale: ru })
      : ""
  },
  set: value => {
    if (timeRegex.test(value)) {
      const splitted = value.split(":")
      internalValues.startTime = setDateValues(new Date(), {
        hours: +splitted[0],
        minutes: +splitted[1],
        seconds: 0,
      })
    }
  },
})
const endTime = computed({
  get: () =>
    internalValues.endTime
      ? format(internalValues.endTime, "HH:mm", { locale: ru })
      : "",
  set: value => {
    if (timeRegex.test(value)) {
      const splitted = value.split(":")
      internalValues.endTime = setDateValues(new Date(), {
        hours: +splitted[0],
        minutes: +splitted[1],
        seconds: 59,
      })
    }
  },
})

const currentYear = computed(() => {
  return getYear(new Date())
})

watch(internalValues, validateInputs)
watch(range, next => {
  emit("update:modelValue", {
    start: next.start && format(next.start, "yyyy-MM-dd'T'HH:mm:ssXXX"),
    end: next.end && format(next.end, "yyyy-MM-dd'T'HH:mm:ssXXX"),
  })
})

const classDate = computed(() => {
  return (type: DateInputType) => ({
    pskit__datepicker_input: true,
    "pskit__datepicker_input-error": errors[type],
  })
})
const classTime = computed(() => {
  return (type: DateInputType) => ({
    pskit__datepicker_input: true,
    "pskit__datepicker_input-time": true,
    "pskit__datepicker_input-error": errors[type],
  })
})

const hasErrors = computed(() => Object.values(errors).includes(true))
const hasRange = computed(() => !!(range.value.start && range.value.end))
watch(
  () => !!(hasRange.value && hasErrors),
  next => emit("toggleSubmitDisabled", !next)
)
onBeforeMount(() => {
  emit("toggleSubmitDisabled", !(hasRange.value || hasErrors.value))
})

const containerWidth = computed(() =>
  typeof props.width === "number" ? `${props.width}px` : props.width
)

const handleBackClick = () => {
  if (datepicker.value) {
    datepicker.value.moveBy(-1)
  }
}

const handleForwardClick = async () => {
  if (datepicker.value) {
    datepicker.value.moveBy(1)
  }
}

const handleShortcutClick = (range: DatePickerShortcuts) => {
  let start = startOfToday()
  const end = endOfToday()

  switch (range) {
    case DatePickerShortcuts.WEEK:
      start = subDays(startOfToday(), 6)
      break

    case DatePickerShortcuts.MONTH:
      start = subDays(startOfToday(), 30)
      break

    case DatePickerShortcuts.YEAR:
      start = startOfYear(new Date())
      break

    case DatePickerShortcuts.CLEAR:
      clearDates()
      return
  }

  internalValues.startDate = start
  internalValues.startTime = start
  internalValues.endDate = end
  internalValues.endTime = end
}

const clearDates = () => {
  internalValues.startDate = null
  internalValues.endDate = null
  internalValues.startTime = null
  internalValues.endTime = null
  errors.startDate = false
  errors.endDate = false
  errors.startTime = false
  errors.endTime = false
}
defineExpose({ clearDates })
</script>

<template>
  <div class="pskit__datepicker" :style="{ width: containerWidth }">
    <slot name="inputs">
      <div v-if="mode === 'range'" class="pskit__datepicker_header">
        <div class="pskit__datepicker_input-group">
          <PbDateInput v-model="startDate" :class="classDate('startDate')" />
          <PbTimePicker
            v-if="withTime"
            v-model="startTime"
            :class="classTime('startTime')"
          />
        </div>
        <span> - </span>
        <div class="pskit__datepicker_input-group">
          <PbDateInput v-model="endDate" :class="classDate('endDate')" />
          <PbTimePicker
            v-if="withTime"
            v-model="endTime"
            :class="classTime('endTime')"
          />
        </div>
      </div>
    </slot>
    <div class="pskit__datepicker_body">
      <VDatePicker
        ref="datepicker"
        v-model.range="range"
        borderless
        :rules="timeRules"
        locale="ru-RU"
        :maxDate="new Date()"
      >
      </VDatePicker>

      <div class="pskit__datepicker_arrows-container">
        <PsButton
          class="pskit__datepicker_arrow pskit__datepicker_arrow-back"
          size="sm"
          :padding="false"
          @click.prevent.stop="handleBackClick"
        >
          <template #prefix>
            <PsIcon
              class="arrowIcon"
              name="triangle-right"
              size="7"
              color="#848484"
            />
          </template>
        </PsButton>

        <PsButton
          class="pskit__datepicker_arrow"
          size="sm"
          :padding="false"
          @click.prevent.stop="handleForwardClick"
        >
          <template #prefix>
            <PsIcon
              class="arrowIcon"
              name="triangle-right"
              size="7"
              color="#848484"
            />
          </template>
        </PsButton>
      </div>
    </div>
    <slot name="shortcuts">
      <PsDatePickerShortcuts
        :currentYear="currentYear"
        @shortcutClicked="handleShortcutClick"
      />
    </slot>
  </div>
</template>

<style lang="postcss">
.pskit__datepicker_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  color: #b3b3b3;
  background-color: #e6f0fc;
  overflow: hidden;
}

.pskit__datepicker_body {
  position: relative;
  display: flex;
  justify-content: center;
}

.pskit__datepicker_input-group {
  display: flex;
  align-items: flex-end;
}

.pskit__datepicker_input {
  font-size: 0.875rem;
  line-height: 1.3rem;
  color: #121212;
  border: 1px solid #dfdfdf;
  background-color: #fff;
  font-family: "PT Astra Sans", "PT Sans", Verdana, sans-serif;
}

.pskit__datepicker_input-error {
  background-color: rgba(208, 2, 27, 0.1);
}
.pskit__datepicker_input::placeholder,
.pskit__datepicker_input::-webkit-input-placeholder,
.pskit__datepicker_input::-moz-placeholder,
.pskit__datepicker_input::-ms-input-placeholder,
.pskit__datepicker_input::-moz-placeholder {
  color: #b3b3b3;
}

.pskit__datepicker_arrows-container {
  position: absolute;
  top: 13px;
  right: 42px;
  display: flex;
  align-items: center;
}

.pskit__datepicker_arrow {
  position: relative;
  width: 15px;
  height: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  vertical-align: middle;
}
.pskit__datepicker_arrow-back {
  margin-right: 10px;
  transform: rotate(180deg);
}
</style>
