<script setup lang="ts">
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import PsQuadbox from "@/ContextTab/components/UI/PsQuadbox.vue"
import { EQuadboxState } from "@/ContextTab/components/UI/types"
import type { CodeFilterState, CodeTreeSelectMode } from "@/ContextTab/modules/news/codes/types"

const props = defineProps<{
  opened: boolean | number
  item: any
  filterState: CodeFilterState
  mode: CodeTreeSelectMode
}>()

const emit = defineEmits<{
  (e: "change", state: EQuadboxState): void,
  (e: "click", event: MouseEvent): void,
}>()

const changeState = (id: string | number) => {
  const currentState = props.filterState[id]?.state
  switch (currentState) {
    case EQuadboxState.unchecked:
      emit("change", EQuadboxState.included)
      break
    case EQuadboxState.included:
      emit("change", EQuadboxState.excluded)
      break
    case EQuadboxState.excluded:
      emit("change", EQuadboxState.unchecked)
      break
    default:
      emit("change", EQuadboxState.included)
  }
}
</script>

<template>
  <div
    class="tree-item"
    :class="{ opened, disabled: item.disabled }"
    @click="emit('click', $event)"
  >
    <PsIcon
      v-if="item?.children?.length"
      name="tree-arrow"
      :size="11"
      class="tree-arrow"
    />
    <template v-if="!item.nonSelectable">
      <template v-if="mode === 'multi'">
        <PsQuadbox
          :state="filterState[item.id]?.state ?? EQuadboxState.unchecked"
          @change="emit('change', $event)"
        />
      </template>
      <template v-else>
        <PsCheckbox
          :checked="filterState[item.id]?.state ?? EQuadboxState.unchecked"
          :checkedValue="EQuadboxState.included"
          :uncheckedValue="EQuadboxState.unchecked"
          @update:checked="emit('change', $event as EQuadboxState)"
        />
      </template>
    </template>
    <template v-if="item.name">
      <span
        :class="filterState[item.id]?.state ?? EQuadboxState.unchecked"
        @click="changeState(item.id)"
      >
        {{ item.name }}
      </span>
    </template>
  </div>
</template>

<style scoped lang="postcss">
.tree-item {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 0.25rem;
  min-height: 24px;
  padding: 2px 0;
  vertical-align: middle;

  & span {
    display: inline-block;
  }
}
.opened {
  .tree-arrow {
    transform: rotate(90deg);
  }
}
.disabled {
  background-color: rgba(255, 67, 67, 0.2);
  opacity: 0.5;
}
</style>
