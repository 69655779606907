import MessageHubClient from "@/lib/messageHub/client"

// FIXE: подумать над тем, где хранить contextId
window.contextId = globalThis.crypto.randomUUID()

const messageHub = new MessageHubClient({
  contextId: window.contextId,
})

export default messageHub
