<script setup lang="ts">
import AuthForm from "./AuthForm.vue"
import ResetPasswordComplete from "./ResetPasswordComplete.vue"
import ResetPasswordFrom from "./ResetPasswordForm.vue"
import { ref, computed, onBeforeMount } from "vue"
import { useRoute, useRouter } from "vue-router"

const router = useRouter()
const route = useRoute()

const resetPasswordToken = computed(() =>
  route.name === "resetPasswordComplete" ? route.params.token : ""
)

enum FormKind {
  auth,
  reset,
  resetCompete,
}

const kind = ref(FormKind.auth)

onBeforeMount(() => {
  if (route.name === "resetPasswordComplete") {
    kind.value = FormKind.resetCompete
  }
})

const back = () => {
  kind.value = FormKind.auth
  router.push({ path: "/" })
}
</script>

<template>
  <AuthForm v-if="kind === FormKind.auth" @reset="kind = FormKind.reset" />
  <ResetPasswordFrom
    v-if="kind === FormKind.reset"
    @return="back"
  />
  <ResetPasswordComplete
    v-if="kind === FormKind.resetCompete"
    :token="resetPasswordToken"
    @return="back"
  />
</template>
