export enum States {
  Idle,
  Loading,
  Result,
}
export type Message = { type: MessageType; text: string }
export type MessageType = "success" | "warn" | "error"
export type MessageRender = "string" | "html"
export type HandlerArgs = { [p: string]: any }
export type TriggerArgs = HandlerArgs & { state: States }
