<script setup lang="ts">
import { ITabs, ITabTitle, ITab } from "@inkline/inkline"
import { useVModel } from "@vueuse/core"
import { useSlots } from "vue"
import type { Size, Tab } from "@/ContextTab/components/UI/types"

interface Props {
  modelValue: Tab["id"]
  tabs: Array<Tab>
  size?: Size
}

const props = withDefaults(defineProps<Props>(), {
  size: "md",
})

const emit = defineEmits(["update:modelValue"])
const slots = useSlots()

const curValue = useVModel(props, "modelValue", emit)
</script>

<template>
  <ITabs v-model="curValue" :size="size" class="ps-tabs">
    <template #header>
      <div v-if="slots.prefix" class="header-prefix">
        <slot name="prefix" />
      </div>

      <div class="tabs-titles">
        <ITabTitle v-for="tab in tabs" :key="tab.id" :for="tab.id">{{
          tab.label
        }}</ITabTitle>
      </div>

      <div v-if="slots.suffix" class="header-suffix">
        <slot name="suffix" />
      </div>
    </template>
    <template #default>
      <ITab v-for="tab in tabs" :key="tab.id" :name="tab.id">
        <slot :tab="tab" />
      </ITab>
    </template>
  </ITabs>
</template>

<style lang="postcss">
.ps-tabs {
  .tabs-header {
    gap: var(--tabs--padding-left);
  }
  .tabs-titles {
    display: flex;
    flex: 1;
    gap: var(--tabs--padding-left);
  }
}
</style>
