<script setup lang="ts">
import CalendarButton from "./buttons/Calendar.vue"
import ColorsButton from "./buttons/Colors.vue"
import ModeSwitcher from "./buttons/ModeSwitcher.vue"
import RepublicationsButton from "./buttons/Republications.vue"
import Toolbar from "@/ContextTab/components/Toolbar/PanelToolbar.vue"
import PsButtonToolbar from "@/ContextTab/components/UI/Button/PsButtonToolbar.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import ColorIndicatorModal from "@/ContextTab/modules/news/published/components/colorIndication/ColorIndicatorModal.vue"
import CalendarModal from "@/ContextTab/modules/news/published/components/dateTime/CalendarModal.vue"
import format from "date-fns/format"
import { ru } from "date-fns/locale"
import parseISO from "date-fns/parseISO"
import { computed, ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { SizeList } from "@/lib/types"
import type { PublishedLocalFiltersStore } from "@/ContextApp/stores/news/published/localFilters"

const emit = defineEmits(["toggleSearch"])

const store = useStore<PublishedLocalFiltersStore>("publishedLocalFilters")

const colorsModal = ref(false)
const calendarModal = ref(false)

const datesSelected = computed(
  () => store.data?.dates?.start && store.data?.dates?.end
)

const dateFormat = "dd.MM.yyyy"

const datesLabel = computed(() => {
  if (!datesSelected.value) return "Дата и время"

  let formattedDates = ""

  const start = store.data?.dates.start || null
  const end = store.data?.dates.end || null

  if (start) {
    formattedDates = format(parseISO(start), dateFormat, { locale: ru })
  }

  if (start && end) {
    formattedDates += " – " + format(parseISO(end), dateFormat, { locale: ru })
  }

  return formattedDates
})

const repFilter = computed(() =>
  store.data?.hideRepublications ? "included" : "unchecked"
)

const breakpoints: SizeList = [
  [0, "xs"],
  [320, "sm"],
  [380, "md"],
  [420, "lg"],
  [520, "xl"],
  [1300, "xxl"],
  [1800, "xxxl"],
]

const updateRepub = () => store.toggleRepublications()

const clearDates = () => store.clearDates()
</script>

<template>
  <Toolbar class="toolbar" background="#0069e4" :breakpoints="breakpoints">
    <template #left>
      <PsButtonToolbar
        :circle="true"
        :border="false"
        @click="emit('toggleSearch')"
      >
        <PsIcon type="font" name="search" size="20" color="#fff" />
      </PsButtonToolbar>

      <CalendarButton
        :datesLabel="datesLabel"
        :datesSelected="!!datesSelected"
        @click="calendarModal = !calendarModal"
        @clear="clearDates"
      />
      <RepublicationsButton :checked="repFilter" @click="updateRepub" />
      <ColorsButton @click="colorsModal = true" />
      <ColorIndicatorModal v-model="colorsModal" />
      <Suspense>
        <CalendarModal v-model="calendarModal" />
      </Suspense>
    </template>
    <template #right>
      <ModeSwitcher />
    </template>
  </Toolbar>
</template>

<style scoped lang="postcss">
.toolbar {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}
</style>
