<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"

defineProps<{
  datesLabel: string
  datesSelected: boolean
}>()

const emit = defineEmits(["click", "clear"])
</script>

<template>
  <PsFilterButton
    :tooltip="datesLabel ?? 'Дата и время'"
    :showTooltip="['xs', 'sm']"
    :showButtonIcon="['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl']"
    :name="datesLabel"
    role="dates"
    :showClear="datesSelected"
    @click="emit('click', $event)"
    @clear="emit('clear')"
  >
    <template #prefix>
      <!-- {{ bracket }} -->
      <PbIcon name="calendar" size="14" />
    </template>
    <template #xs></template>
    <template #sm>Дата и время</template>
    <template #md>
      {{ datesLabel }}
    </template>
  </PsFilterButton>
</template>
