import { watch } from "vue"
import { useStoreAsync } from "@/ContextTab/useStore"
import deepToRaw from "@/utils/deepToRaw"
import type { UserProfileStore } from "@/ContextApp/stores/userProfile"

export default async function usePassProfile() {
  const userProfile = await useStoreAsync<UserProfileStore>(
    "userProfile", 
    { shared: true }
  )

  return async (legacyApp: any) => {
    legacyApp.$store.dispatch("userProfile/installProfile", {
      profile: deepToRaw(userProfile.data.profile),
      initial: true,
    })

    const updateProfile = async () => {
      legacyApp.$store.dispatch("panels/panelsSetUpdating", true)

      await legacyApp.$store.dispatch("userProfile/installProfile", {
        profile: deepToRaw(userProfile.data.profile),
      })

      legacyApp.$store.dispatch("panels/panelsSetUpdating", false)
    }

    watch(() => userProfile.data.profile, updateProfile)

    await legacyApp.$store.dispatch("panels/init")

    legacyApp.$store.dispatch("userProfile/init", 2)
  }
}
