import { toRaw, unref } from "vue"
import { filterPrivate } from "@/utils/filters"

const updatableFileds = [
  "colors",
  "panels",
  "default_dateline_agency",
  "default_dateline_city",
  "default_news_type_id",
  "default_recipient_group_id",
  "default_recipient_user_id",
  "filters",
  "fullscreen",
  // "icq_uin",
  "newsEditor",
  "publishedPanelMode",
]

export function makeProfilePatch(profile: any) {
  const raw = toRaw(unref(profile))
  const patch = updatableFileds.reduce((acc, field) => {
    acc[field] = raw?.[field] ?? null
    return acc
  }, {})

  if (globalThis.structuredClone) {
    return filterPrivate(globalThis.structuredClone(toRaw(patch)))
  } else {
    return filterPrivate(JSON.parse(JSON.stringify(toRaw(patch))))
  }
}
