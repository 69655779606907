<script setup lang="ts">
import Resizer from "../Resizer.vue"
import { useStorage } from "@vueuse/core"
import { computed, reactive, watchEffect } from "vue"

const props = withDefaults(
  defineProps<{
    fluid?: boolean
    columns?: (number | string)[]
    minSizes?: number[]
  }>(),
  {
    fluid: false,
    columns: () => [0, "auto", 0],
    minSizes: () => [100, 310, 100],
  }
)

const stored = useStorage("ps-layout-published-vertical", {
  grid: [200, 450, 100],
})
const grid = reactive(stored.value.grid)

watchEffect(() => {
  stored.value = { grid }
})

const onResize = (index: number, diffs: { x: number; y: number }) => {
  const newSize = grid[index - 1] + diffs.x
  if (newSize > props.minSizes[index - 1]) {
    grid[index - 1] += diffs.x
  }
}

const gridColumns = computed(() => props.columns.join(" "))
</script>

<template>
  <div class="layout" :class="{ fluid }">
    <div :style="{ flexBasis: grid[0] + 'px' }">
      <slot name="left" />
    </div>
    <Resizer v-if="fluid" @resize="onResize(1, $event)" />
    <div class="main" :style="{ flexBasis: grid[1] + 'px' }">
      <section id="popups-panel-vertical" class="popups"></section>
      <slot name="main" />
    </div>
    <Resizer v-if="fluid" @resize="onResize(2, $event)" />
    <div class="preview" :style="{ flexBasis: grid[2] + 'px' }">
      <slot name="preview" />
    </div>
  </div>
</template>

<style scoped lang="postcss">
/* @media screen and (min-width: 576px) { */
.layout {
  &.fluid {
    display: flex;
    height: 100%;
    max-height: 100%;
    flex-direction: row;

    > div {
      min-width: 100px;
      /* flex-grow: 1; */

      &:last-child {
        flex-grow: 1;
      }
    }
  }
}

/* } */

.main {
  position: relative;
}

.preview {
  overflow: hidden;
}

@media screen and (max-width: 575.99px) {
  /*   .menu-block {
    margin-top: auto;
  } */

  .layout {
    display: grid;
    /* grid-template-columns: 0 auto 0; */
    grid-template-columns: v-bind(gridColumns);
    grid-template-rows: 100%;
    height: 100%;
    max-height: 100%;
    width: 100%;

    /*     &.with-menu {
      grid-template-columns: var(--workspace-switcher-size) 328px auto;
    }
 */
    /* & aside {
      grid-row: 1 / -1;
      flex-direction: column;
    } */
  }
}
</style>
