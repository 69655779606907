// ок что импорт не найден:
// https://vite-pwa-org.netlify.app/guide/auto-update.html#importing-virtual-modules
import { registerSW } from "virtual:pwa-register"
import { ref, type Ref } from "vue"

const CHECK_UPDATES_INTERVAL = 15 * 60 * 1000 // 15min

// код основан на vite-plugin-pwa

class AppUpdate {

  private swRegistration: ReturnType<typeof registerSW>
  hasUpdate: Ref<boolean> = ref(false)

  constructor() {
    this.swRegistration = registerSW({
      onNeedRefresh: () => {
        this.hasUpdate.value = true
      },
      onRegisteredSW: (swScriptUrl: string, reg: ServiceWorkerRegistration) => {
        // начинаем проверять обновления
        reg && setInterval(() => reg.update(), CHECK_UPDATES_INTERVAL)
      }
    })
  }

  update() {
    this.swRegistration()
  }
}

export default new AppUpdate()
