export interface Props {
  submitDisabled?: boolean
  top?: string
  right?: string
  bottom?: string
  left?: string
  height?: string
  width?: string
}

export const propsDefaults = {
  submitDisabled: false,
  top: "0",
  right: "0",
  bottom: "0",
  left: "0",
  height: "75vh",
  width: "100%",
}
