function defaultSearch({
  list,
  items,
  predicates = [],
}: {
  list: any[]
  items: any
  predicates: (({
    searchString,
    params,
  }: {
    searchString: string
    params: object
  }) => boolean)[]
}) {
  const filtered = []
  const usedItems: { [key: string]: any } = {}
  for (const item of list) {
    const check = predicates.reduce((acc, predicate) => {
      return acc && predicate(item)
    }, true)
    if (check) {
      if (item.track.length) {
        item.track.forEach((uid: string) => {
          const itemFromTrack = items[uid]
          if (itemFromTrack) {
            if (itemFromTrack.path === "000000000000000000") return
            if (!usedItems[uid]) {
              usedItems[uid] = true
              filtered.push(itemFromTrack)
            }
          }
        })
      }
      usedItems[item.uid] = true
      filtered.push(item)
    }
  }
  return filtered
}

export type TreeSearchParams = {
  enabled: boolean
  autofocus: boolean
  minimalQueryLength: number
  caseSensitive: boolean
  initialQuery: string
  fields: string[]
  throttle: number
  searchFunction: typeof defaultSearch
  predicates: any[]
}

const SearchParamsDefault = (): TreeSearchParams => ({
  enabled: true,
  autofocus: true,
  minimalQueryLength: 1,
  caseSensitive: false,
  initialQuery: "",
  fields: ["name", "label"],
  throttle: 100,
  searchFunction: defaultSearch,
  predicates: [],
})

const makeSearchParams = (params: Partial<TreeSearchParams> = {}) => ({
  ...SearchParamsDefault(),
  ...params,
})

export default function () {
  return {
    makeSearchParams,
  }
}
