<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
// import useFilterButtton from "./composables/useFilterButton"

defineProps<{
  count: number
}>()

const emit = defineEmits(["click", "clear"])
// const { size } = useFilterButtton()
</script>

<template>
  <PsFilterButton
    tooltip="Ленты и направления публикации"
    showTooltip="always"
    name="Ленты"
    :showClear="count > 0"
    :count="count"
    :plural="['лента', 'ленты', 'лент']"
    size="sm"
    @click="emit('click', $event)"
    @clear="emit('clear')"
  >
    <template #prefix>
      <PbIcon name="newswires" css="stroke-width: unset;" size="14" />
    </template>
    <template #xs></template>
    <template #md>Ленты</template>
  </PsFilterButton>
</template>
