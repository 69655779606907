<script setup lang="ts">
import PublishedPreviewToolbar from "./PublishedPreviewToolbar.vue"
import Panel from "@/ContextTab/components/Panel/Panel.vue"
import Toolbar from "@/ContextTab/components/Toolbar/PanelToolbar.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsDropdown from "@/ContextTab/components/UI/PsDropdown.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import WithInplaceMessage from "@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue"
import PublishedItemView from "@/ContextTab/modules/news/published/components/PublishedItemView.vue"
import PublishedNewsHistory from "@/ContextTab/modules/news/published/components/PublishedNewsHistory.vue"
import RelationsNewsModal from "@/ContextTab/modules/news/published/components/RelationsNewsModal.vue"
import { useToggle } from "@vueuse/core"
import { computed, ref, watch } from "vue"
import useNewsActions from "@/ContextTab/modules/news/composables/useNewsActions"
import useShareModal from "@/ContextTab/modules/news/composables/useShareModal"
import { newsAction } from "@/ContextTab/modules/news/published/components/types"
import { useStore } from "@/ContextTab/useStore"
import type { Message } from "@/ContextTab/components/UI/WithInplaceMessage/types"
import type { SizeList } from "@/lib/types"
import type { RefsStore } from "@/ContextApp/stores/references"
import type { NewsStore } from "@/ContextApp/stores/news"
import type { AuthStore } from "@/ContextApp/stores/auth"

const props = defineProps<{
  item: any
  mode: 1 | 2
  searchMode: boolean
}>()

const emit = defineEmits(["open", "revoke"])

const REVOKE_PERMISSION_CODE = "2.11"

const refsStore = useStore<RefsStore>("references", {
  shared: true,
})

const newsStore = useStore<NewsStore>("news", {
  shared: true,
})

const userAuth = useStore<AuthStore>("auth", {
  shared: true,
})

const loading = ref(false)
const message = ref<Message | null>(null)

const newsLanguage = computed(() => {
  return (
    refsStore.data?.references?.News.Language[
      props.item?.version?.language_id
    ] ?? "RU"
  )
})

async function republishUneditedNewsItem(ids: number[]) {
  if (newsStore.created) {
    return newsStore.republishUnedited(props.item.id, ids)
  }
  return false
}

async function onSubmit(ids: number[]) {
  loading.value = true
  const result = await republishUneditedNewsItem(ids)
  message.value = result.payload.message
  loading.value = false
}

const { extend, republish, revoke, open } = useNewsActions(loading, message)

const [newsActionDialogVisible, toggleNewsActionDialogVisible] = useToggle()
const [relationsDialogVisible, toggleRelationsDialogVisible] = useToggle()
const { showShareModal, message: shareMessage } = useShareModal()

function onSelectActionHandler(action: keyof typeof newsAction) {
  switch (action) {
    case "showHistory":
      toggleNewsActionDialogVisible(true)
      break
    case "revoke":
      revoke(props.item.id)
      break
    case "showRelations":
      toggleRelationsDialogVisible(true)
      break
    case "sendToAsoi":
      showShareModal({ itemId: props.item.id, actionType: "sendToAsoi" })
  }
}

const options = computed(() => {
  const options = [
    {
      label: newsAction.sendToAsoi.label,
      key: newsAction.sendToAsoi.key,
    },

    {
      label: newsAction.showHistory.label,
      key: newsAction.showHistory.key,
    },
    {
      label: newsAction.showRelations.label,
      key: newsAction.showRelations.key,
    },
    {
      label: newsAction.revoke.label,
      key: newsAction.revoke.key,
      show:
        props.item.publisher?.id === userAuth.data.tokenData?.sub ||
        userAuth.data.tokenData?.permissions.includes(REVOKE_PERMISSION_CODE),
    },
  ]

  return options
})

watch(shareMessage, next => {
  message.value = next
})

const toolbarSizes: SizeList = [
  [0, "xs"],
  [280, "sm"],
  [390, "md"],
  [500, "lg"],
  [1000, "xl"],
  [1200, "xxl"],
  [1800, "xxxl"],
]
</script>

<template>
  <WithInplaceMessage :loading="loading" :message="message">
    <Panel class="preview-panel" background="#f2f2f2" accent="#f2f2f2">
      <template #titlebar>
        <Toolbar :breakpoints="toolbarSizes" class="titlebar">
          <template #left>
            <PublishedPreviewToolbar
              :item="item"
              :panelMode="mode"
              :newsLanguage="newsLanguage"
              @extend="extend(item.id)"
              @republish="republish(item.id)"
              @submit="onSubmit"
            />
          </template>

          <template #right>
            <PsTooltip content="Открыть в новой вкладке">
              <PsButton size="sm" @click="open(item.id)">
                <PbIcon name="open-out" />
              </PsButton>
            </PsTooltip>

            <PsDropdown :options="options" @select="onSelectActionHandler">
              <PsButton size="sm">
                <PbIcon name="menu-burger" />
              </PsButton>
            </PsDropdown>
          </template>

          <PublishedNewsHistory
            v-if="newsActionDialogVisible && item.id"
            :id="item.id"
            v-model:show="newsActionDialogVisible"
          />

          <RelationsNewsModal
            v-model="relationsDialogVisible"
            :item="item"
            @open="emit('open', $event)"
          />
        </Toolbar>
      </template>

      <template #content>
        <div class="content">
          <PublishedItemView
            :newsItem="item"
            :version="item.version || {}"
            :searchMode="searchMode"
          />
        </div>
      </template>
    </Panel>
  </WithInplaceMessage>
</template>

<style lang="postcss" scoped>
.preview-panel {
  --toolbar-shadow: none;

  & ::v-deep(.panel-toolbar) {
    border-bottom: 1px solid #c9d4e1;
  }

  .titlebar {
    position: relative;
  }

  .content {
    overflow-y: auto;
  }
}
</style>
