<script setup lang="ts">
import ListItem from "./PanelListItem.vue"
import DateDelimiter from "../Date/DateDelimiter.vue"
import CircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import Error from "@/ContextTab/components/Error/Error.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsEmpty from "@/ContextTab/components/UI/PsEmpty.vue"
import { useElementVisibility } from "@vueuse/core"
import { VirtualList } from "pskit"
import { ref, toRef, watch } from "vue"
import useDateDelimiters from "./useDateDelimiters"
import type { NewsItemPublished } from "@/ContextTab/modules/news/published/components/types"

/** по идее должен быть дженериком */
type ItemType = NewsItemPublished

const props = withDefaults(
  defineProps<{
    items: ItemType[]
    hasDateDelimiters?: boolean
    dateDelimeterColor?: string
    initialSize?: number
    selectedItemId: number | string
    errors?: any[] | null
    hasMoreItems?: boolean
    isFetching?: boolean
  }>(),
  {
    hasDateDelimiters: true,
    dateDelimeterColor: "#eee",
    initialSize: 100,
    errors: null,
    hasMoreItems: true,
    isFetching: false,
  }
)

const emit = defineEmits(["select", "reachedEnd", "reachedStart"])

const { currentDate, needDateDelimiter } = useDateDelimiters(
  toRef(props, "items")
)

const list = ref(null)
const loadingTrigger = ref(null)

const loadingTriggerVisible = useElementVisibility(loadingTrigger)

watch(loadingTriggerVisible, (next, prev) => {
  if (next && next !== prev) {
    emit("reachedEnd", true)
  }
})

const scrollToTop = () => {
  list.value?.scrollToTop()
}

const getScrollTopSize = () => list.value?.$el.scrollTop

defineExpose({
  scrollToTop,
  getScrollTopSize,
})
</script>

<template>
  <div class="panel-list">
    <slot name="before" />
    <template v-if="errors?.length">
      <Error :errors="errors">
        <template #request-error>
          <slot name="request-error"></slot>
        </template>
      </Error>
    </template>
    <VirtualList
      v-else
      ref="list"
      :items="items"
      :initialSize="initialSize"
      :keyboardEnabled="true"
      :vimNavigation="false"
      :selectedId="selectedItemId"
      @selected="emit('select', $event)"
      @reachedEnd="emit('reachedEnd', $event)"
      @reachedStart="emit('reachedStart', $event)"
    >
      <template #default="{ item, index, selected }">
        <ListItem :item="item">
          <DateDelimiter
            v-if="hasDateDelimiters && needDateDelimiter(index)"
            :key="currentDate"
            :date="item.status_modified_at"
            :color="dateDelimeterColor"
          />

          <slot :item="item" :index="index" :isSelected="selected"></slot>
        </ListItem>
      </template>
      <template #empty>
        <div v-if="isFetching" class="empty-loader-wrapper">
          <CircularLoader />
        </div>
        <div v-else class="empty-text">
          <PsEmpty title="Ничего не найдено" />
          <slot name="empty-actions" />
        </div>
      </template>
      <template #after>
        <div v-show="items?.length > 0 && hasMoreItems" class="loader-wrapper">
          <div ref="loadingTrigger" class="loading-trigger"></div>
          <CircularLoader v-show="isFetching" />
          <PsButton
            v-show="!isFetching"
            round
            @click="emit('reachedEnd', true)"
          >
            Загрузить ещё
          </PsButton>
        </div>
      </template>
    </VirtualList>
  </div>
</template>

<style scoped lang="postcss">
.panel-list {
  height: 100%;
  /* overflow-y: auto; */
}

.empty-loader-wrapper,
.loader-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 80px;
  align-items: center;
  width: 100%;
  position: relative;

  .loading-trigger {
    position: absolute;
    visibility: hidden;
    pointer-events: none;
    top: -30vh;
  }
}

.empty-loader-wrapper {
  height: 100%;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}
.empty-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
