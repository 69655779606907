export default function (number: number, words: string[]): string {
  if (number % 10 === 1 && number % 100 !== 11) {
    return words[0]
  } else if (
    number % 10 >= 2 &&
    number % 10 <= 4 &&
    (number % 100 < 10 || number % 100 >= 20)
  ) {
    return words[1]
  } else {
    return words[2]
  }
}
