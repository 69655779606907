type ILayout = "cyr" | "lat"
type IExcept = "except"

const layoutMap: Record<ILayout | IExcept, string> = {
  cyr: "аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ",
  lat: "fF,<dDuUlLtT`~;:pPbBqQrRkKvVyYjJgGhHcCnNeEaA[{wWxXiIoO]}sSmM'\".>zZ",
  except: "бБёЁжЖхХъЪэЭюЮ",
}

/**
 * Функция для замены символов раскладки с исключениями для символов.
 *
 * @exports
 * @param string
 * @returns {string}
 */
export function translateLayout(string: string): string {
  const isCyr = string.search(/[а-яА-ЯЁё]/g) !== -1
  const isLat = string.search(/[a-zA-Z]/g) !== -1

  // Если в строке смешаны обе раскладки, возвращаем её как есть
  if (!(isCyr !== isLat)) return string

  let fromLayout: ILayout, toLayout: ILayout
  if (isCyr && !isLat) {
    fromLayout = "cyr"
    toLayout = "lat"
  }
  if (isLat && !isCyr) {
    fromLayout = "lat"
    toLayout = "cyr"
  }

  return Array.from(string)
    .map(symbol => {
      // Проверяем исключения
      if (layoutMap.except.includes(symbol)) return symbol

      const pos = layoutMap[fromLayout].indexOf(symbol)
      return pos !== -1 ? layoutMap[toLayout][pos] : symbol
    })
    .join("")
}
