<script setup lang="ts">
import MainNavButton from "@/ContextTab/components/MainNav/MainNavButton.vue"

const workspaces = [
  {
    name: "published",
    icon: "workspace/published",
    label: "Опубликованные",
    color: "hsla(212, 100%, 52%, 1)",
  },
  // {
  //   name: "embargo",
  //   icon: "workspace/embargo",
  //   label: "Отложенные",
  //   color: "hsla(212, 100%, 52%, 1)",
  // },
  // {
  //   name: "editor",
  //   icon: "workspace/editor",
  //   label: "Создать новость",
  //   color: "hsla(212, 100%, 52%, 1)",
  // },
  // {
  //   name: "search",
  //   icon: "workspace/search",
  //   label: "Поиск",
  //   color: "hsla(212, 100%, 52%, 1)",
  // },
]
</script>

<template>
  <MainNavButton
    v-for="ws of workspaces"
    :key="ws.name"
    v-bind="ws"
    :active="ws.name === 'published'"
  />
</template>

<style lang="postcss">
.switcher-button-wrapper {
  width: 100%;
}

.switcher-button.selected {
  background: radial-gradient(
    32.43% 52.38% at 100% 47.96%,
    hsl(212, 100%, 52%) 0%,
    #000000 100%
  );
}
</style>
