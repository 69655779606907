import { useStoreAsync } from "@/ContextTab/useStore"
import deepToRaw from "@/utils/deepToRaw"
import type { RefsStore } from "@/ContextApp/stores/references"

export default async function usePassRefs() {
  const refsStore = await useStoreAsync<RefsStore>("references", { shared: true })

  return async (legacyApp: any) => {
    legacyApp.$store.commit(
      "references/SET_REFERENCES",
      deepToRaw(refsStore.data.references)
    )
    legacyApp.$store.commit("references/REFERENCES_INIT", 2)
  }
}
