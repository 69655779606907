export default [
  "#4D3400",
  "#751102",
  "#743E01",
  "#999100",
  "#B47A00",
  "#E92308",
  "#FF6907",
  "#FF8A04",
  "#FFAD00",
  "#F3C500",
  "#00F49F",
  "#00C0B4",
  "#C16A26",
  "#A94D24",
  "#B52E29",
  "#3BA900",
  "#00E926",
  "#A2FF1E",
  "#CEDD15",
  "#719A11",
  "#007681",
  "#0084A8",
  "#009DF5",
  "#0000C2",
  "#9E00A9",
  "#560082",
  "#670060",
  "#E500F6",
  "#B50360",
  "#E90F3A",
  "#DC6C6B",
  "#F63C6C",
  "#81212C",
  "#F533B8",
  "#711DCF",
  "#367EF5",
  "#142F5A",
  "#116657",
  "#158234",
  "#FF0889",
  "#A77550",
  "#DC6C6B",
  "#FFC67B",
  "#DC8A69",
  "#95DC65",
  "#4D682F",
  "#53B487",
  "#72E9F3",
  "#74C8F4",
  "#567AB5",
  "#F3BFB5",
  "#CDAE98",
  "#FFECBD",
  "#D2E8A9",
  "#826BE9",
  "#D778FF",
  "#E971A2",
  "#743668",
  "#385674",
  "#8C7C68",
  "#A0D9CE",
  "#BCFFBC",
  "#737B9A",
  "#ABB9E8",
  "#B4DDF3",
  "#A0D9CE",
  "#E2BCFF",
  "#A47AA7",
  "#E8ABBB",
  "#57499B",
]

export const codeColors = [
  "#4D3400",
  "#751102",
  "#743E01",
  "#999100",
  "#B47A00",
  "#E92308",
  "#FF6907",
  "#FF8A04",
  "#FFAD00",
  "#F3C500",
  "#00F49F",
  "#00C0B4",
  "#C16A26",
  "#A94D24",
  "#B52E29",
  "#3BA900",
  "#00E926",
  "#A2FF1E",
  "#CEDD15",
  "#719A11",
  "#007681",
  "#0084A8",
  "#009DF5",
  "#0000C2",
  "#9E00A9",
  "#560082",
  "#670060",
  "#E500F6",
  "#B50360",
  "#E90F3A",
  "#DC6C6B",
  "#F63C6C",
  "#81212C",
  "#F533B8",
  "#711DCF",
  "#367EF5",
  "#142F5A",
  "#116657",
  "#158234",
  "#A77550",
  "#4D682F",
  "#53B487",
  "#567AB5",
  "#E971A2",
  "#743668",
  "#385674",
  "#8C7C68",
  "#737B9A",
  "#A47AA7",
  "#57499B",
]

export const forbiddenEntityColors = {
  TERRORIST_GROUPS: "#ED5C5C",
  UNWANTED_FOREIGN_ORGANIZATIONS: "#B8B233",
  NONPROFIT_EXTREMIST_ORGANIZATIONS: "#4DBB4B",
  RELIGIOUS_OR_PUBLIC_EXTREMIST_ACTIVITIES: "#604D1E",
  MEDIA_FOREIGN_AGENTS: "#EEC45C",
  NONPROFIT_FOREIGN_AGENTS: "#5C85EE",
  UNREGISTERED_FOREIGN_AGENTS: "#5C85EE",
  PERSON_FOREIGN_AGENTS: "#50889E",
  UNIVERSAL_REGISTRY_OF_FOREIGN_AGENTS: "#9607b2",
}
