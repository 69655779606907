<script setup lang="ts">
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { IRadioButtons } from "@inkline/inkline"
import useProfile, {
  PanelMode,
} from "@/ContextTab/modules/user/composables/useProfile"

const { publishedPanelMode, updatePublishedPanelMode } = useProfile({})

interface PanelModeItem {
  value: PanelMode
  label: string
  icon: string
}

const panelMode: PanelModeItem[] = [
  {
    value: PanelMode.horizontal,
    label: "Вертикальный",
    icon: "panel-layout-vertical",
  },
  {
    value: PanelMode.vertical,
    label: "Горизонтальный",
    icon: "panel-layout-horizontal",
  },
]
</script>

<template>
  <IRadioButtons
    :modelValue="publishedPanelMode"
    class="ps-published-panel-mode-switcher"
    :options="panelMode"
    size="sm"
    variant="button-group"
  >
    <template #option="{ option }">
      <PsTooltip :content="option.label">
        <PsIcon
          :name="option.icon"
          :size="28"
          :color="
            publishedPanelMode === option.value
              ? '#fff'
              : 'rgba(255, 255, 255, 0.7)'
          "
          @click="updatePublishedPanelMode(option.value)"
        />
      </PsTooltip>
    </template>
  </IRadioButtons>
</template>

<style lang="postcss">
.ps-published-panel-mode-switcher {
  --checkable-button-group--background: transparent;
  --checkable-button-group--border-width: 0;
  --checkable-button-group--box-shadow: none;
  --checkable-button-group--padding: 0 !important;
  --checkable-button-group--sm--padding-top: 0;
  --checkable-button-group--sm--padding-right: 0;
  --checkable-button-group--sm--padding-bottom: 0;
  --checkable-button-group--sm--padding-left: 0;

  .button {
    --button--padding: 0 !important;
    --button--border-width: 0 !important;
  }
}
</style>

<style lang="postcss" scoped>
.ps-published-panel-mode-switcher {
  /*   --n-button-color-active: transparent !important;
  --n-button-color: transparent !important;
  --n-button-border-color-active: transparent !important;
  --n-button-border-color: transparent !important;
  padding-right: 0.125em;
  padding-left: 0.125em;
 */
  --checkable-button-group--background: transparent;
  --checkable-button-group--border-width: 0;
  --checkable-button-group--box-shadow: none;
  --checkable-button-group--padding: 0 !important;
  --button-padding: 0 !important;
}
</style>
