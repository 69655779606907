import { computed } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { AuthStore } from "@/ContextApp/stores/auth"

export default function useAuthInfo() {
  const store = useStore<AuthStore>("auth", {
    shared: true,
  })

  const userId = computed(() => store.data.tokenData?.sub ?? null)

  const getUserPermissions = computed(
    () => store.data.tokenData?.permissions ?? []
  )

  const getUserHasSuperUserPermissions = (permissionCodes: string[]) => {
    if (!getUserPermissions.value.includes("1.1")) return false
    // на эти коды права суперпользователя не распространяются
    return permissionCodes.every(code => !["2.6", "2.9", "2.11"].includes(code))
  }

  const getIsUserHasAllPermissions = (permissionCodes: string[]) =>
    Boolean(getUserPermissions.value) &&
    permissionCodes.reduce((acc, value) => {
      if (getUserHasSuperUserPermissions(permissionCodes)) {
        return true
      }
      if (acc === false) return false
      if (getUserPermissions.value.includes("" + value)) {
        return true
      }
      return false
    }, true)

  const getIsUserHasAnyPermission = (permissionCodes: string[]) =>
    Boolean(getUserPermissions.value) &&
    permissionCodes.reduce((acc, value) => {
      if (getUserHasSuperUserPermissions(permissionCodes)) {
        return true
      }
      if (acc === true) return true
      if (getUserPermissions.value.includes("" + value)) {
        return true
      }
      return false
    }, false)

  return {
    userId,
    getUserPermissions,
    getIsUserHasAllPermissions,
    getIsUserHasAnyPermission,
  }
}
