<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PsCheckbox from "@/ContextTab/components/UI/PsCheckbox.vue"
import useFilterButton from "./composables/useFilterButton"

defineProps<{
  checked: string
}>()

const emit = defineEmits(["click"])
// const { size } = useFilterButton()
</script>

<template>
  <PsFilterButton
    :showTooltip="['xs', 'sm', 'md', 'lg']"
    tooltip="Скрыть повторы"
    name="Скрыть повторы"
    size="sm"
    @click="emit('click')"
  >
    <template #prefix>
      <PsCheckbox
        :checked="checked"
        checkedValue="included"
        uncheckedValue="unchecked"
      />
    </template>
    <template #xs></template>
    <template #xl> Скрыть повторы </template>
  </PsFilterButton>
</template>
