<script setup lang="ts">
import PsPopover from "@/ContextTab/components/UI/Popover/PsPopover.vue"
import { vMaska } from "maska"
import { watch, computed, ref, reactive } from "vue"
import type { Ref } from "vue"

const props = withDefaults(
  defineProps<{
    modelValue?: string
    placeholder?: string
    disabled?: boolean
    interval?: number
    scrollToCurrentHour?: boolean
    withSeconds?: boolean
  }>(),
  {
    modelValue: "",
    placeholder: defaultPlaceholder,
    disabled: false,
    interval: 1,
    scrollToCurrentHour: true,
    withSeconds: false,
  }
)

const emit = defineEmits(["update:modelValue", "focus", "blur"])

defineOptions({
  inheritAttrs: false,
})

const defaultPlaceholder = "--:--"
const hoursList: Ref<Element | null> = ref(null)

const time = ref(props.modelValue)
const tokens = {
  H: { pattern: /[0-2]/ }, // Старший разряд часов
  h: { pattern: /[0-3]/ }, // Младший разряд часов
  M: { pattern: /[0-5]/ }, // Старший разряд минут,
  S: { pattern: /[0-5]/ }, // Старший разряд секунд,
  "#": { pattern: /\d/ }, // Младший разряд минут (любое число)
}
const correctTimeRegex = props.withSeconds
  ? /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
  : /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/

const isDropdownVisible = ref(false)

const hours: string[] = []
for (let i = 0; i < 24; i++) {
  hours.push(i < 10 ? `0${i}` : String(i))
}

const minutes: string[] = []
for (let i = 0; i < 60; i += props.interval) {
  minutes.push(i < 10 ? `0${i}` : String(i))
}

const seconds: string[] = []
for (let i = 0; i < 60; i += props.interval) {
  seconds.push(i < 10 ? `0${i}` : String(i))
}

const mask = reactive({
  mask: (value: string) => {
    let mask

    if (value.length) {
      mask = parseInt(value[0]) < 2 ? "H#:M#" : "Hh:M#"
      if (props.withSeconds) {
        mask += ":S#"
      }
      return mask
    }

    return props.withSeconds ? "H#:M#:S#" : "H#:M#"
  },
  tokens,
})

const $placeholder = computed(() => {
  if (props.placeholder !== defaultPlaceholder) return props.placeholder
  return props.withSeconds ? "--:--:--" : "--:--"
})

const currentHour = computed(() => {
  return time.value ? time.value.split(":")[0] : null
})

const currentMinute = computed(() => {
  return time.value ? time.value.split(":")[1] : null
})

const currentSecond = computed(() => {
  return time.value && props.withSeconds ? time.value.split(":")[2] : null
})

watch(
  () => props.modelValue,
  next => {
    time.value = next
  }
)

const update = () => {
  if (correctTimeRegex.test(time.value)) {
    emit("update:modelValue", time.value)
  }
}

const select = (type: "minute" | "hour" | "second", value: string) => {
  const [hours, mins, seconds] = time.value
    ? time.value.split(":")
    : ["00", "00", "00"]

  if (type === "hour") {
    time.value = value + ":" + mins
  }

  if (type === "minute") {
    time.value = hours + ":" + value
  }

  if (type === "second" && props.withSeconds) {
    time.value = time.value + ":" + seconds
  }

  update()
}

const toggleDropdown = (event: Event) => {
  if (props.disabled) return
  if (!correctTimeRegex.test(time.value)) time.value = props.modelValue
  if (isDropdownVisible.value) {
    isDropdownVisible.value = false
    update()
  } else {
    const { target } = event
    if ((target as HTMLElement).closest(".pskit__timepicker")) {
      isDropdownVisible.value = true
    }
  }
}

const openDropdown = (event: Event) => {
  if (props.disabled) return
  if (!correctTimeRegex.test(time.value)) time.value = props.modelValue
  const { target } = event
  if ((target as HTMLElement).closest(".pskit__timepicker")) {
    isDropdownVisible.value = true
  }
}
</script>

<template>
  <PsPopover
    placement="bottom"
    trigger="manual"
    :open="isDropdownVisible"
    :arrow="false"
    :offset="0"
    class="time-picker-popover"
    @clickOutside="(isDropdownVisible = false)"
  >
    <div class="pskit__timepicker" v-bind="$attrs">
      <input
        v-model="time"
        v-maska:[mask]
        class="pskit__timepicker_input"
        :placeholder="$placeholder"
        :disabled="disabled"
        type="tel"
        @input="update"
        @focus="$emit('focus')"
        @blur="$emit('blur')"
        @keydown.tab.enter="toggleDropdown"
        @click.prevent.stop="openDropdown"
      />
    </div>
    <template #body>
      <div class="pskit__timepicker_list-wrapper">
        <ul ref="hoursList" class="pskit__timepicker_list">
          <li
            v-for="h in hours"
            :key="h"
            class="pskit__timepicker_list-item"
            :class="{
              'pskit__timepicker_list-item--active': h === currentHour,
            }"
            @click.stop.capture="select('hour', h)"
          >
            {{ h }}
          </li>
        </ul>
        <ul
          class="pskit__timepicker_list"
          data-test-selector="timepicker-list-minutes"
        >
          <li
            v-for="m in minutes"
            :key="m"
            class="pskit__timepicker_list-item"
            :class="{
              'pskit__timepicker_list-item--active': m === currentMinute,
            }"
            @click.stop.capture="select('minute', m)"
          >
            {{ m }}
          </li>
        </ul>
        <ul v-if="withSeconds" class="pskit__timepicker_list">
          <li
            v-for="s in seconds"
            :key="s"
            class="pskit__timepicker_list-item"
            :class="{
              'pskit__timepicker_list-item--active': s === currentSecond,
            }"
            @click.stop.capture="select('second', s)"
          >
            {{ s }}
          </li>
        </ul>
      </div>
    </template>
  </PsPopover>
</template>

<style lang="postcss">
.time-picker-popover {
  --popover--height: 116px;
  --popover--background: white;
  --popover--padding: 0;
  --popover--border-radius: 0;
  height: 116px;
}
</style>

<style lang="postcss" scoped>
.pskit__timepicker {
  position: relative;
}

.pskit__timepicker_input {
  background-color: white;
  border: 0;
  /* color: ; */
  display: inline-flex;
  height: 16px;
  padding: 0;
  text-align: center;
  /* width: 100%; */
  width: 5ch;
}

.pskit__timepicker_input:focus {
  box-shadow: none;
  outline: 0;
}

::v-deep(.pskit__timepicker_dropdown) {
  background: #fff;
  border-radius: 0;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
  color: rgba(#000, 0.87);
  height: 116px;
  overflow: hidden;
  padding: 0;
  width: 100px;
}

/* ::v-deep(i.pskit__timepicker_dropdown-body) {
  height: 100%;
  overflow: hidden;
} */

.pskit__timepicker_list-wrapper {
  display: flex;
  flex-basis: 50%;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: default;
}

.pskit__timepicker_list {
  flex: 1;
  list-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 2px 0;
  margin: 0;
}

.pskit__timepicker_list-item {
  padding: 0 5px;
  text-align: center;
}

.pskit__timepicker_list-item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.pskit__timepicker_list-item--active {
  background: #0069e4;
  color: #fff;
}
</style>
