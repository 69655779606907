// Спасибо Hulkmaster, https://github.com/vuejs/core/issues/5303#issuecomment-1543596383
import { toRaw, isRef, isReactive, isProxy } from "vue"

const objectIterator = <T>(input: any): any => {
  if (Array.isArray(input)) {
    return input.map(item => objectIterator(item))
  }
  if (isRef(input) || isReactive(input) || isProxy(input)) {
    return objectIterator(toRaw(input))
  }
  if (input && typeof input === "object") {
    return Object.keys(input).reduce((acc, key) => {
      acc[key as keyof typeof acc] = objectIterator(input[key])
      return acc
    }, {} as T)
  }
  return input
}

export default function deepToRaw<T extends Record<string, any>>(source: T): T {
  return objectIterator<T>(source)
}
