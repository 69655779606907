<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsModal from "@/ContextTab/components/UI/PsModal.vue"

defineProps<{
  show: boolean
}>()

const emit = defineEmits(["toggle"])

function generateExcteption() {
  throw new Error("Error generated from debug interface")
}
</script>

<template>
  <PsModal
    :show="show"
    :hideOnClickOutside="true"
    :dismissible="true"
    header="Интерфейс отладки"
    color="warning"
    @update:show="emit('toggle', $event)"
  >
    <PsButton color="primary" @click="generateExcteption">
      Вызвать исключение
    </PsButton>
  </PsModal>
</template>
