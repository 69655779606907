import { computed, toRef, type Ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { CodeFilterTypesWithAll, CodeItemsMap } from "@/ContextTab/modules/news/codes/types"
import type { CodesStore } from "@/ContextApp/stores/codes"

export default function useFilterCodes(
  type: Ref<CodeFilterTypesWithAll> | CodeFilterTypesWithAll = "all"
) {
  const store = useStore<CodesStore>("codes", { shared: true })

  const typeRef = toRef(type)

  const allCodes = computed<CodeItemsMap>(() => store.data.actualCodes)
  const allCodesByUid = computed<CodeItemsMap>(() => store.data.allActualCodesByUid)

  const filtered = computed<CodeItemsMap>(() => {
    switch (typeRef.value) {
      case "feeds":
        return store.data.feedAndPub as CodeItemsMap
      case "non-feeds":
        return store.data.nonFeed as CodeItemsMap
      default:
        return allCodes.value
    }
  })

  const filteredIds = computed<string[]>(() => {
    switch (typeRef.value) {
      case "feeds":
        return store.data.feedAndPubIds as string[]
      case "non-feeds":
        return store.data.nonFeedIds as string[]
      default:
        return Object.keys(allCodes.value)
    }
  })

  const filteredByUid = computed<CodeItemsMap>(() => {
    switch (typeRef.value) {
      case "feeds":
        return store.data.feedAndPubByUid as CodeItemsMap
      case "non-feeds":
        return store.data.nonFeedByUid as CodeItemsMap
      default:
        return Object.values(allCodes.value)
          .reduce(
            (acc, item) => {
              acc[item.uid] = item;
              return acc
            },
            {} as CodeItemsMap
          )
    }
  })

  const filteredUids = computed<string[]>(() => {
    switch (typeRef.value) {
      case "feeds":
        return store.data.feedAndPubUids
      case "non-feeds":
        return store.data.nonFeedUids
      default:
        return Object.values(allCodes.value).map((item) => item.uid)
    }
  })

  const isCodeIdIncluded = (codeId: string | number) =>
    filteredIds.value?.includes(codeId.toString())

  return {
    allCodes,
    allCodesByUid,
    filtered,
    filteredIds,
    isCodeIdIncluded,
    filteredByUid,
    filteredUids,
  }
}
