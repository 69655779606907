<script lang="ts">
import differenceInDays from "date-fns/differenceInDays"
import format from "date-fns/format"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import isThisMonth from "date-fns/isThisMonth"
import isThisYear from "date-fns/isThisYear"
import isToday from "date-fns/isToday"
import { ru } from "date-fns/locale"

export default {
  name: "PbDateDelimiter",
  props: {
    date: {
      type: [String, Date],
      required: true,
    },
    color: {
      type: String,
      default: "#eeeeee",
    },
  },

  computed: {
    formatted() {
      const date = new Date(this.date)
      if (isToday(date)) {
        return "Сегодня"
      }
      let distance
      if (differenceInDays(Date.now(), date) < 1) {
        distance = "Вчера"
      } else {
        distance = formatDistanceToNow(date, { locale: ru, addSuffix: true })
      }
      const formatted = format(date, this.makeFormatString(date), {
        locale: ru,
      })
      return `${distance}, ${formatted}`
    },

    fontColor() {
      // const normalizedColor = this.color
      //   .replace(/^#+/g, '#')
      //   .replace(/^[a-h\d]+/g, '#$&')
      // return Color(normalizedColor).isLight() ? '#000' : '#fff'
      return "#333"
    },
  },

  methods: {
    makeFormatString(date) {
      let format = ""
      if (!isThisMonth(date) || !isToday(date)) format += "d MMMM "
      if (!isThisYear(date)) format += "yyyy "
      return format
    },
  },
}
</script>

<template>
  <div class="date-delimiter" data-test-selector="date-delimiter">
    <div
      class="date-delimiter__date"
      :style="{ background: color, color: fontColor }"
    >
      {{ formatted }}
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.date-delimiter {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.date-delimiter__date {
  padding: 2px 5px;
  font-size: 0.75rem;
  font-weight: 700;
  border-radius: 2px;
}
</style>
