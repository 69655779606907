import * as Sentry from "@sentry/vue"
import { SENTRY_DSN, VERSION, getCurrentEnv, DEBUG } from "@/config"
import type { App } from "vue"
import type { SeverityLevel } from "@sentry/types"

type UserData = {
  id?: number | null
  name?: string | null
  email?: string | null
  asoiName?: string | null
}

class TabMetrics {
  protected _userData: UserData = {
    id: undefined,
    name: undefined,
    email: undefined,
  }

  _generateDebugException?: () => void
  _generateSentryMessage?: () => void

  constructor() {
    if (DEBUG) {
      this._generateDebugException = () => {
        Sentry.captureException(new Error("Debug exception from APP"))
      }

      this._generateSentryMessage = () => {
        Sentry.captureMessage("Debug message from APP")
      }
    }
    this.setUser = this.setUser.bind(this)
  }

  init(app: App) {
    Sentry.init({
      app,
      dsn: SENTRY_DSN,
      debug: false,
      enabled: ["production", "testing"].includes(getCurrentEnv()),
      integrations: [],
      release: VERSION,
      environment: getCurrentEnv(),
      normalizeDepth: 8,
    })
  }
    setUser(userData: UserData) {
      this._userData = {
        ...this._userData,
        ...userData,
      }
      Sentry.setUser({ ...this._userData, username: this._userData.name })
    }

    sendMessage(message: string, level: SeverityLevel) {
      Sentry.captureMessage(message, level)
    }

    sendExcetion(error: unknown) {
      Sentry.captureException(error)
    }
}

export default new TabMetrics()
