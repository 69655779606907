<script lang="ts" setup>
import PsCircularLoader from "@/ContextTab/components/Busy/Loader/Circular.vue"
import PsEmpty from "@/ContextTab/components/UI/PsEmpty.vue"
import PsUsersListEntry from "@/ContextTab/components/Users/PsUsersListEntry.vue"
import { VirtualList } from "pskit"
import { ref, computed } from "vue"
import type { IUser } from "@/ContextTab/modules/news/published/components/types"

interface Props {
  entries?: IUser[]
  searchQuery?: string
  checkedItems?: IUser[]
  multipleMode?: boolean
  loaded?: boolean
  loading?: boolean
  useLayoutTranslation?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  entries: () => [],
  searchQuery: "",
  checkedItems: () => [],
  multipleMode: false,
  loaded: false,
  loading: false,
  useLayoutTranslation: false,
})

const emit = defineEmits(["selected", "submit", "loadMore"])

const entriesIndexed = computed(() =>
  props.entries.map((entry, index) => ({ id: index, entry }))
)

function isEntryChecked(entry: IUser) {
  return props.checkedItems.some(
    item => item.is_group === entry.is_group && item.id === entry.id
  )
}

function toggleEntry(entry: IUser) {
  let checkedItems = props.checkedItems
  if (props.multipleMode) {
    const index = checkedItems.findIndex(item => {
      return item.is_group === entry.is_group && item.id === entry.id
    })
    if (index >= 0) {
      checkedItems.splice(index, 1)
    } else {
      checkedItems.push(entry)
    }
  } else {
    checkedItems = [entry]
  }
  emit("selected", checkedItems)
}

/* ---- Управление скроллом списка ------ */
const list = ref<InstanceType<typeof VirtualList> | null>(null)
const scrollToTop = () => {
  list.value?.scrollToTop()
}
defineExpose({
  scrollToTop,
})
/* -------------------------------------- */
</script>

<template>
  <div v-if="entries" class="ps-users-list">
    <VirtualList
      v-if="!!entries.length"
      ref="list"
      :items="entriesIndexed"
      @reachedEnd="emit('loadMore')"
    >
      <template #default="{ item, index, selected }">
        <slot :item="item" :index="index" :isSelected="selected">
          <div class="ps-users-list__item">
            <PsUsersListEntry
              :id="item.id"
              :entry="item.entry"
              :searchQuery="searchQuery"
              :isCheckable="true"
              :isChecked="isEntryChecked(item.entry)"
              :useLayoutTranslation="useLayoutTranslation"
              @toggle="toggleEntry(item.entry)"
            >
              <template #optional-data="{ entry }">
                <slot name="optional-data" v-bind="{ entry }" />
              </template>
            </PsUsersListEntry>
          </div>
        </slot>
      </template>
    </VirtualList>

    <PsCircularLoader v-if="!entries.length && loading" :size="50" />

    <slot v-if="searchQuery.length && !entries.length" name="empty">
      <PsEmpty
        v-if="searchQuery.length && !entries.length"
        description="Ничего не найдено"
      />
    </slot>
  </div>
</template>

<style lang="postcss" scoped>
.ps-users-list {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.ps-users-list__item {
  margin-bottom: -1px;
  /* height: 67px; */
  display: flex;
  cursor: pointer;
  transition: all 0.1s linear;

  &:hover {
    /* TODO: Выразить цвет в дизайн токенах */
    background-color: rgb(232, 238, 253);
  }
}
</style>
