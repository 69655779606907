<script setup lang="ts">
import NewPresetButton from "./buttons/NewPreset.vue"
import PresetsButton from "./buttons/Presets.vue"
import ResetButton from "./buttons/ResetPreset.vue"
import SaveButton from "./buttons/SavePreset.vue"
import Collapsable from "@/ContextTab/components/Toolbar/Collapsable.vue"
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PsDropdownIcon from "@/ContextTab/components/UI/PsDropdownIcon.vue"
import { inject, ref } from "vue"
import type { SizeBracket } from "@/lib/types"

defineProps<{
  preset: any
  configuratorOpen: boolean
  saveDisabled: boolean
  filtersEmpty: boolean
  filters: any
}>()

const emit = defineEmits(["toggle", "save", "apply", "clear"])

const bracket: SizeBracket = inject("toolbarSize", "md")

const showButtonsPopover = ref(false)

const onButtonClick = (
  type: "apply" | "clear" | "toggle" | "save",
  event?: any
) => {
  if (showButtonsPopover.value) {
    showButtonsPopover.value = false
  }
  emit(type, event)
}
</script>

<template>
  <Collapsable v-model="showButtonsPopover" :bracket="bracket" collapseOn="xs">
    <template #trigger>
      <PsFilterButton @click.capture.stop.prevent="() => null">
        <template #prefix>
          <PsDropdownIcon
            :open="showButtonsPopover"
            @click.capture.stop.prevent="() => null"
          />
        </template>
      </PsFilterButton>
    </template>
    <PresetsButton
      :open="configuratorOpen"
      :preset="preset"
      @click="onButtonClick('toggle')"
    />
    <ResetButton v-if="preset" @clear="onButtonClick('clear')" />
    <SaveButton
      :preset="preset"
      :disabled="saveDisabled"
      @click="onButtonClick('save')"
    />
    <NewPresetButton
      :disabled="saveDisabled || filtersEmpty"
      :filters="filters"
      @apply="onButtonClick('apply', $event)"
    />
  </Collapsable>
</template>
