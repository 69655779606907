<script setup lang="ts">
import Horizontal from "./panelGrid/Horizontal.vue"
import Vertical from "./panelGrid/Vertical.vue"
import { computed } from "vue"

const props = withDefaults(
  defineProps<{ fluid?: boolean; gridType?: 1 | 2 }>(),
  {
    fluid: true,
    gridType: 1,
  }
)

const gridComponent = computed(() =>
  props.gridType === 1 ? Vertical : Horizontal
)
</script>

<template>
  <component :is="gridComponent" :fluid="fluid">
    <template #left><slot name="left" /></template>
    <template #main><slot name="main" /></template>
    <template #preview><slot name="preview" /></template>
  </component>
</template>
