import Legacy from "@/ContextTab/modules/legacy/Legacy.vue"
import PublishedWorkspace from "@/ContextTab/modules/news/published/components/Workspace.vue"
import { createRouter, createWebHistory } from "vue-router"

export const router = createRouter({
  history: createWebHistory(),

  routes: [
    {
      path: "/",
      redirect: { name: "legacy", params: {} },
    },
    {
      path: "/published",
      name: "published",
      component: PublishedWorkspace,
    },
    {
      path: "/new",
      redirect: { name: "published", params: {} },
    },
    {
      path: "/legacy",
      component: Legacy,
      name: "legacy",
    },
    {
      path: "/legacy:legacyPath(.*)",
      component: Legacy,
      name: "legacyInternal",
    },
    {
      path: "/tasks",
      redirect: { name: "legacy", params: {} },
    },
    {
      path: "/entities",
      redirect: { path: "legacy/entities" },
      name: "entities",
    },
    {
      path: "/reset-password/:token",
      name: "resetPasswordComplete",
      component: PublishedWorkspace,
    },
    {
      path: "/:pathMatch(.*)*",
      // При редиректе без указания params появляется ворнинг. Передача пустого объекта params - способ обойти
      // появление ворнинга (https://github.com/vuejs/router/issues/1617#issuecomment-1320941431)
      redirect: { name: "published", params: {} },
    },
  ],
})
