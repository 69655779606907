<script setup lang="ts">
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue"
import { GatewayStatus } from "@/lib/webSocket/statuses"

const props = withDefaults(
  defineProps<{
    status?: GatewayStatus
    online?: boolean
  }>(),
  {
    status: GatewayStatus.initial,
    online: true,
  }
)

const visible = ref(false)
const timeout = ref<number | null>(null)
const interval = ref<ReturnType<typeof setInterval> | null>(null)

let initialLoad = true

const connectionState = computed(() => {
  switch (props.status) {
    case GatewayStatus.closed:
    case GatewayStatus.failed:
      return "offline"

    case GatewayStatus.reconnecting:
    case GatewayStatus.connecting:
    case GatewayStatus.connection_lost:
      return "connecting"

    default:
      return props.online ? "online" : "connecting"
  }
})

const messageType = computed(() => {
  switch (props.status) {
    case GatewayStatus.closed:
    case GatewayStatus.failed:
      return "error"

    case GatewayStatus.reconnecting:
      return "loading"

    default:
      return "success"
  }
})

const TEXT = {
  offline: "Потеряно соединение с сервером",
  connecting: "Пытаемся восстановить соединение с сервером",
  online: "Подключено",
}

const text = computed(() => {
  return TEXT[connectionState.value]
})

watch(connectionState, (next, prev) => {
  if (next !== prev) {
    if (initialLoad) {
      initialLoad = false
      return
    }
    if (next === "online") {
      timeout.value = setTimeout(() => {
        visible.value = false
        clearTimeout(timeout.value)
        timeout.value = null
      }, 1500)
    } else {
      visible.value = true
    }
  }
})

onMounted(() => {
  interval.value = setInterval(() => {
    if (connectionState.value === "online" && timeout.value === null) {
      visible.value = false
    }
  }, 5000)
  if (connectionState.value === "offline") {
    visible.value = true
  }
})

onBeforeUnmount(() => {
  clearInterval(interval.value)
})
</script>

<template>
  <div v-if="visible">
    <div
      v-if="connectionState === 'online'"
      class="connection-status online"
    >
      {{ text }}
    </div>
    <div
      v-else-if="connectionState === 'offline'"
      class="connection-status offline"
    >
      {{ text }}
    </div>
    <div v-else class="connection-status connecting">
      {{ text }}
    </div>
  </div>
</template>

<style scoped>
.message {
  position: absolute;
  display: block;
  z-index: 102;
}
.connection-status {
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.14),
    0 6px 10px 0 rgba(0, 0, 0, 0.1);

  color: #fff;
  min-height: 24px;
  position: fixed;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  font-size: 20px;
  z-index: 101;
}

.offline {
  background-color: rgba(255, 82, 82, 0.8);
}

@keyframes progress-bar-stripes {
  from {
    background-position: 24px 0;
  }
  to {
    background-position: 0 0;
  }
}

.connecting {
  background-color: rgba(255, 193, 7, 1);
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 24px 24px;
  animation: progress-bar-stripes 1s linear infinite;
}

.connected {
  background-color: #4caf50;
}

.online {
  background-color: rgba(76, 175, 80, 0.8);
}
</style>
