<script setup lang="ts"></script>

<template>
  <div class="ps-button-group">
    <slot />
  </div>
</template>

<style scoped lang="postcss">
.ps-button-group {
  display: inline-flex;
  padding: 0;

  :deep(.ps-button) {
    --button--border-top-left-radius: 0;
    --button--border-top-right-radius: 0;
    --button--border-bottom-right-radius: 0;
    --button--border-bottom-left-radius: 0;

    &:first-child {
      --button--border-left-width: 1px;
      --button--border-top-left-radius: var(--border-top-left-radius);
      --button--border-bottom-left-radius: var(--border-bottom-left-radius);
      --button--border-right-color: transparent;
    }
    &:last-child {
      --button--border-right-width: 1px;
      --button--border-left-width: 1px;
      --button--border-top-right-radius: var(--border-top-right-radius);
      --button--border-bottom-right-radius: var(--border-bottom-right-radius);
    }

    & + :is(.ps-button) {
      --button--border-right-width: 0 !important;
      --button--border-right-color: transparent;

    }

    &.-active {
      --button--border-top-color: var(--color--primary);
      --button--border-right-color: var(--color--primary);
      --button--border-bottom-color: var(--color--primary);
      --button--border-left-color: var(--color--primary);
    }
  }
}
</style>
