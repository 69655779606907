<script setup lang="ts">
import { computed, ref, onMounted, watchEffect } from "vue"

const props = withDefaults(
  defineProps<{
    codes?: any[]
    preferableGroupIds?: (string | number)[]
    tipGetter?: (code: any) => string
    nameGetter?: (code: any) => string
    delimiter?: string
    invertedColors?: boolean
    codesFilter?: (code: any) => boolean
  }>(),
  {
    codes: () => [],
    preferableGroupIds: () => [],
    tipGetter: (code: any) => code.name,
    nameGetter: (code: any) => code.name,
    delimiter: ", ",
    invertedColors: false,
    codesFilter: (code?: any) => true,
  }
)

const helper = ref(null)

const sortedCodes = computed(() => {
  if (!props.preferableGroupIds.length) return props.codes

  return [...props.codes].sort((a, b) => {
    const aCategoryIsPreferable = props.preferableGroupIds.includes(
      a.code?.category?.id
    )
    const bCategoryIsPreferable = props.preferableGroupIds.includes(
      b.code?.category?.id
    )
    if (aCategoryIsPreferable && !bCategoryIsPreferable) return -1
    if (!aCategoryIsPreferable && bCategoryIsPreferable) return 1
    return 0
  })
})

const filteredCodes = computed(() => {
  return sortedCodes.value.filter(code => props.codesFilter(code.code))
})

const $mounted = ref(false)
const visibleCodesCount = ref(0)
// const visibleCodesCount = computed(() => {
//   return 5
// })
// TODO: recalculate style здесь создаёт проблему с производительностью
const updateVisibleCodesCount = () => {
  if (!helper.value) {
    visibleCodesCount.value = 0
    return
  }
  helper.value.textContent = ""

  // добавляем span-ы и считаем видимые
  const spans = filteredCodes.value
    .map(code => `<span>${props.nameGetter(code.code)}</span>`)
    .join(props.delimiter)

  helper.value.insertAdjacentHTML("beforeend", spans)

  const count = [...helper.value.children].filter(
    el => el.offsetLeft < helper.value.offsetWidth
  ).length

  helper.value.textContent = ""

  visibleCodesCount.value = count
}
// visibleCodesCount() {
//   this.updateKeys // запускает пересчет свойства по изменениям в updateKeys

//   const h = this.$refs.helper
//   if (!h) return 0

//   // очищаем helper
//   h.textContent = ""

//   // добавляем span-ы и считаем видимые
//   const spans = this.filteredCodes
//     .map(code => `<span>${this.nameGetter(code.code)}</span>`)
//     .join(this.delimiter)
//   h.insertAdjacentHTML("beforeend", spans)

//   const count = [...h.children].filter(
//     el => el.offsetLeft < h.offsetWidth
//   ).length

//   // удаляем span-ы (они больше не нужны, но это опционально)
//   h.textContent = ""

//   return count
// },

const visibleCodes = computed(() => {
  return filteredCodes.value.slice(0, visibleCodesCount.value || 0)
})

const restCodes = computed(() => {
  return filteredCodes.value.slice(visibleCodesCount.value)
})

watchEffect(() => {
  if (helper.value && filteredCodes.value) {
    updateVisibleCodesCount()
  }
})

onMounted(() => {
  $mounted.value = true
  // updateVisibleCodesCount()
})
</script>

<template>
  <div class="wrapper">
    <!-- helper -->
    <div class="helper">
      <div ref="helper"></div>
    </div>

    <!-- root -->
    <div class="inline-codes">
      <div>
        <span v-for="({ code }, index) in visibleCodes" :key="code.id">
          <!-- v-tooltip="{
              // TODO: решить, что делать с тултипом, сейчас он просто дублирует code.name (раньше он показывал полное имя)
              content: tipGetter(code),
              class: 'NewsCodesInline-tooltip',
            }" -->
          <span>{{ nameGetter(code) }}</span>
          <span v-if="index !== visibleCodes.length - 1">{{ delimiter }}</span>
        </span>
      </div>
      <div>
        <span v-if="restCodes.length" class="more">
          &nbsp;+{{ restCodes.length }}
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.inline-codes,
.helper {
  white-space: nowrap;
  font-size: 13px;
  line-height: calc(18 / 12);

  color: #0681ec;
  text-shadow: 0.7px 0 #ccc;

  width: 100%;

  /*
  Двухколоночный грид:
  (1) видимые коды
  (2) каунтер скрытых кодов
  +----------------------------------------------+--+
  |code, code, code, code, code, code, code, co..|+3|
  +----------------------------------------------+--+
  */
  display: grid;
  grid-template-columns: fit-content(100%) max-content;

  & div:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /*   &_invertedColors {
    color: #fff;
  } */
}

.wrapper {
  /*
    Нужен только для того, чтобы позиционировать относительно
    него helperDiv, иначе компонент зависел бы от окружения
  */
  position: relative;
  grid-column: 1 / -1;
  width: 100%; /* предотвращает width=0, когда родитель является flex-контейнером */
}

.helper {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 40px; /* Запрещает слишком короткие сокращения в конце строки */
  visibility: hidden;

  /* to debug */
  min-height: 17px;
  background-color: rgb(196, 183, 183);
}
</style>
