import { computed, type Ref } from "vue"
import { type Message } from "@/ContextTab/components/UI/WithInplaceMessage/types"
import { useStore } from "@/ContextTab/useStore"
import type { ShareNewsStore } from "@/ContextApp/stores/shareNews"

export type ShareManagerOptions = {
  itemId: number | null
  actionType: "sendToEdit" | "sendToAsoi" | null
}

export default function useShareModal() {
  const store = useStore<ShareNewsStore>("shareNews")

  const shareModalVisible = computed(
    () => store.data?.shareModalVisible ?? false
  )

  const modalState = computed(() => store.data?.modalState ?? {})

  const message: Ref<Message | null> = computed(() => {
    return store.data?.message ?? null
  })

  const shareDispatcher = async (payload: any) => {
    if (!modalState.value.itemId) {
      return
    }

    let response
    switch (modalState.value.actionType) {
      case "sendToEdit":
        response = await store.send(payload, modalState.value.itemId)
        break
      case "sendToAsoi":
        response = await store.sendToAsoi(payload, modalState.value.itemId)
        break
    }
  }

  return {
    shareModalVisible,
    modalState,
    showShareModal: (state: ShareManagerOptions) => store.showShareModal(state),
    hideShareModal: () => store.hideShareModal(),
    shareDispatcher,
    message,
  }
}
