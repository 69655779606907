<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import useFilterButtton from "./composables/useFilterButton"

defineProps<{
  count: number
}>()

const emit = defineEmits(["click", "clear"])
// const { size } = useFilterButtton()
</script>

<template>
  <PsFilterButton
    tooltip="Пользователь является автором новости или выпускающим редактором"
    showTooltip="always"
    :showClear="count > 0"
    name="Авторы"
    :count="count"
    size="sm"
    :plural="['польз.', 'польз.', 'польз.']"
    @click="emit('click', $event)"
    @clear="emit('clear')"
  >
    <template #prefix>
      <PbIcon name="user" css="stroke-width: unset;" size="14" />
    </template>
    <template #xs></template>
    <template #md>Польз.</template>
    <template #lg>Пользователи</template>
  </PsFilterButton>
</template>
