import { computed, inject, ref } from "vue"

export default function usePresetButton() {
  const bracket = inject("toolbarSize", ref("md"))

  const size = computed(() =>
    ["xs", "sm", "md"].includes(bracket.value) ? "md" : "sm"
  )

  return { bracket, size }
}
