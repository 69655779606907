<script setup lang="ts">
import NewsCodesTreeItem from "./NewsCodesTreeItem.vue"
import Tree from "@/ContextTab/components/Tree/Tree.vue"
import { ref } from "vue"
import useTreeSearch from "@/ContextTab/components/Tree/useTreeSearch"
import type { CodeFilterState, CodeTreeSelectMode } from "@/ContextTab/modules/news/codes/types"

withDefaults(
  defineProps<{
    items: any
    filterState: CodeFilterState
    mode?: CodeTreeSelectMode
    allOpened?: boolean
    emptyText?: string
  }>(),
  {
    mode: "check",
    allOpened: false,
    emptyText: "Ничего не найдено",
  }
)

const emit = defineEmits(["change"])

const tree = ref<InstanceType<typeof Tree> | null>(null)

const { makeSearchParams } = useTreeSearch()

const searchParams = makeSearchParams()

const onClick = (event: MouseEvent) => {
  if (
    event?.target
    && (event.target instanceof Element)
    && event.target.closest(".tree-arrow")
  ) {
    return
  }
  event.stopPropagation()
}

const scrollToTop = () => {
  tree.value?.scrollToTop()
}
defineExpose({
  scrollToTop,
})
</script>

<template>
  <Tree
    ref="tree"
    :searchParams="searchParams"
    :items="items"
    :initialSize="24"
    :showDepth="allOpened ? Infinity : 1"
    :emptyText="emptyText"
    idField="uid"
    :padding="32"
    :buffer="300"
  >
    <template #default="{ item, opened }">
      <NewsCodesTreeItem
        :item="item"
        :filterState="filterState"
        :opened="allOpened || opened || false"
        :mode="mode"
        @click="onClick"
        @change="emit('change', item, $event)"
      />
    </template>
  </Tree>
</template>

<style scoped lang="postcss"></style>
