<script setup lang="ts">
import PsButton from "./PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import { useSlots, inject, h, ref, type Ref } from "vue"
import type { Size, Color } from "@/ContextTab/components/UI/types"
import type { SizeBracket } from "@/lib/types"

const props = withDefaults(
  defineProps<{
    size: Size
    color?: Color
    tooltip?: string
    showTooltip?: string[] | "always"
    showButtonIcon?: string[]
    tooltipPlacement?: string
    buttonComp?: any
    disabled?: boolean
  }>(),
  {
    tooltip: "",
    showTooltip: () => ["xs", "sm"],
    showButtonIcon: () => ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"],
    tooltipPlacement: "bottom",
    buttonComp: PsButton,
    disabled: false,
    color: undefined,
  }
)

const emit = defineEmits(["click"])

defineSlots<{
  xs?(): any
  sm?(): any
  md?(): any
  lg?(): any
  xl?(): any
  xxl?(): any
  xxxl?(): any
  default?(): any
  prefix?(): any
  suffix?(): any
}>()

const bracketList: SizeBracket[] = ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"]

const slots = useSlots()
const bracket = inject<Ref<SizeBracket>>("toolbarSize", ref("md"))

const renderContent = () => {
  const bracketIndex = bracketList.findIndex(b => b === bracket.value)
  if (bracketIndex !== -1) {
    let closest: SizeBracket = "xs"

    for (let i = 0; i < bracketList.length; i++) {
      const name = bracketList[i]
      if (i <= bracketIndex && slots[name]) {
        closest = name
      }
    }
    if (slots[closest] instanceof Function) {
      return slots?.[closest]?.()
    }
    return slots.default ? slots.default() : null
  }
}

const renderSlot = (name: string) => {
  return slots?.[name]?.()
}

const render = () =>
  h(
    PsTooltip,
    {
      content: props.tooltip,
      disabled:
        props.showTooltip !== "always" &&
        !props.showTooltip.includes(bracket.value),
      placement: props.tooltipPlacement,
    },
    {
      default: () =>
        h(
          props.buttonComp,
          {
            ...props,
            onClick: event => emit("click", event),
          },
          {
            default: () => renderContent() ?? null,
            prefix: () => renderSlot("prefix"),
            suffix: () => renderSlot("suffix"),
          }
        ),
    }
  )
</script>

<template>
  <render />
</template>
