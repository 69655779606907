export enum GatewayStatus {
  initial = "initial",
  closed = "closed",
  connection_lost = "connection_lost",
  connecting = "connecting",
  connected = "connected",
  reconnecting = "reconnecting",
  reconnected = "reconnected",
  failed = "failed",
}
