import { EQuadboxState } from "@/ContextTab/components/UI/types"
import type { Reactive, Ref } from "vue"

type GenericIdType = string | number
type GenericFilterState = Record<GenericIdType, { id: GenericIdType, state: EQuadboxState }>
type GenericItem = {
  id: number,
  uid: string,
  children: GenericIdType[],
  track?: GenericIdType[],
}
type GenericItemsMap = Record<GenericIdType, GenericItem>

export function useFilterTreeUtils(
  filterState: Reactive<GenericFilterState>,
  itemsById: Ref<GenericItemsMap>,
  itemsByUid: Ref<GenericItemsMap>,
) {

  function getCodeSubtreeFlatten(rootUid: string, includeRoot: boolean): GenericItem[] {
    const result: GenericItem[] = []
    const root = itemsByUid.value[rootUid]
    if (!root) {
      return result
    }
    if (includeRoot) {
      result.push(root)
    }
    root.children?.forEach((uid) => {
      result.push(...getCodeSubtreeFlatten(String(uid), true))
    })
    return result
  }

  function isExplicitState(state: EQuadboxState): boolean {
    return [EQuadboxState.included, EQuadboxState.excluded].includes(state)
  }

  function removePartiallySelected(filterState: GenericFilterState): GenericFilterState {
    const result: GenericFilterState = {}
    Object.keys(filterState)
      .forEach((id) => {
        if (isExplicitState(filterState[id].state)) {
          result[id] = filterState[id]
        }
      })
    return result
  }

  function recalcPartiallySelected() {
    const notPartial = removePartiallySelected(filterState)

    const explicitItemsSorted = Object.keys(filterState)
      .filter((id) => isExplicitState(filterState[id].state))
      .map((id) => itemsById.value[id])
      .sort((a, b) => (a.track?.length ?? 0) - (b.track?.length ?? 0)) // parent first!

    // filterState is reactive, so need to reset all keys first
    Object.keys(filterState).forEach((id) => delete filterState[id])
    Object.assign(filterState, { ...notPartial })

    explicitItemsSorted.forEach((item) => {
      const rootState = filterState[item.id].state

      const subtreeItems = getCodeSubtreeFlatten(item.uid, false)
        .filter((item) => !notPartial[item.id])

      const partialState = rootState === EQuadboxState.included
        ? EQuadboxState.partial
        : EQuadboxState.partialExcluded

      subtreeItems.forEach((item) => {
        // nested subtrees will overwrite outer ones
        filterState[item.id] = {id: item.id, state: partialState}
      })
    })
  }

  return {
    removePartiallySelected,
    recalcPartiallySelected,
  }
}
