<script setup lang="ts">
import NewsCodesTree from "./codesTree/NewsCodesTree.vue"
import PsIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { reactive, computed } from "vue"
import useRepublishCodes from "../composables/useRepublishCodes"

const props = defineProps<{
  omit?: (number | string)[]
  profileName?: string
  lang?: string
}>()

const emit = defineEmits(["update"])

const states = {
  unchecked: "unchecked",
  included: "included",
}

// TODO: использование этого компонента вызывает большое потребление памяти
const { items } = useRepublishCodes({
  lang: props.lang,
  omit: props.omit,
})

const filterState: { [key: string]: number[] } = reactive({})

const includeItem = (item: any) => {
  const record: any = filterState[item.id] ?? { id: item.id, state: "included" }
  record.state = "included"
  filterState[item.id] = record
  emit("update", filterState)
}

const uncheckItem = (item: any) => {
  const record: any = filterState[item.id] ?? {
    id: item.id,
    state: "unchecked",
  }
  record.state = "unchecked"
  filterState[item.id] = record
  emit("update", filterState)
}

const selectedCodes = computed(() =>
  Object.values<{ id: string | number; state: string }>(filterState)
    .filter(
      ({ id, state }) =>
        items.value &&
        items.value[id] &&
        (state === states.included || state === states.excluded)
    )
    .map(({ id, state }) => ({
      item: items.value?.[id],
      state,
    }))
)

const changeItemState = (item: any, state: string) => {
  if (item.nonSelectable || item.disabled) return
  if (state === "included") {
    includeItem(item)
  }
  if (state === "unchecked") {
    uncheckItem(item)
  }
}
</script>

<template>
  <div class="wrapper">
    <div class="tree-wrapper">
      <NewsCodesTree
        :items="items"
        :filterState="filterState"
        mode="check"
        @change="changeItemState"
      />
    </div>
    <div v-if="selectedCodes?.length" class="codes-shelf">
      <span>Добавленные коды:</span>
      <div class="codes-list">
        <div
          v-for="code of selectedCodes"
          :key="code.item.id"
          :class="code.state"
        >
          <span>{{ code.item.name }}</span>
          <PsIcon
            type="font"
            name="close"
            size="14"
            class="remove-code"
            @click="uncheckItem(code.item)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.tree-wrapper {
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}
.codes-shelf {
  padding-top: 1rem;
  width: 100%;
  font-size: 0.75rem;

  & > span {
    display: block;
    margin-bottom: 10px;
  }

  .codes-list {
    display: flex;
    gap: 6px;
    width: 100%;
    flex-flow: wrap;

    & > div {
      align-items: center;
      display: flex;
      border-radius: 2px;
      padding: 0 3px 0 6px;
    }
    .included {
      border: 1px solid #9dbccd;
      background: #d9ebfb;
    }
    .excluded {
      border: 1px solid #cd9d9d;
      background: #fbd9d9;
    }

    .remove-code {
      cursor: pointer;
      margin-top: 1px;
    }
  }
}
</style>
