// ###############################
// Настройки интерфейса
// ###############################

import colorsList from "./colors"
// import { hex2hsl } from "@/utils"

export const panelWidth = 280
export const schemes = [
  {
    name: "sepia",
    theme: "light",
    label: "Желтый",
    accentColor: "BD985C",
    backgroundColor: "FFF6E6",
    borderColor: "E99E18",
    color: "000000",
    control: "linear-gradient(180deg, #FFE091 0%, #FFD282 100%)",
    controlColor: "rgba(0, 0, 0, 0.6)",
  },
  {
    name: "blue",
    theme: "light",
    label: "Синий",
    accentColor: "A0CBF2",
    backgroundColor: "EEF7FF",
    borderColor: "1471DE",
    color: "000000",
    control: "linear-gradient(180deg, #78C6F2 0%, #78B8F2 100%)",
    controlColor: "rgba(255, 255, 255, 1)",
  },
  {
    name: "purple",
    theme: "light",
    label: "Фиолетовый",
    accentColor: "BE9DF2",
    backgroundColor: "F0ECF6",
    borderColor: "5D2BAD",
    color: "000000",
    control: "linear-gradient(180deg, #D38CFF 0%, #AA79F9 100%)",
    controlColor: "rgba(255, 255, 255, 1)",
  },
  {
    name: "green",
    theme: "light",
    label: "Зелёный",
    accentColor: "88DFA0",
    backgroundColor: "F0FBF3",
    borderColor: "1AA942",
    color: "000000",
    control: "linear-gradient(180deg, #6DF072 0%, #5BDF80 100%)",
    controlColor: "rgba(0, 0, 0, 0.6)",
  },
  {
    name: "red",
    theme: "light",
    label: "Красный",
    accentColor: "F39292",
    backgroundColor: "F9EFEF",
    borderColor: "BA3030",
    color: "000000",
    control: "linear-gradient(180deg, #FB997A 0%, #F36B6B 100%)",
    controlColor: "rgba(255, 255, 255, 1)",
  },
  {
    name: "black",
    theme: "dark",
    label: "Черный",
    accentColor: "464D53",
    backgroundColor: "596168",
    borderColor: "000000",
    color: "DCDCDC",
    control: "linear-gradient(180deg, #556266 0%, #383E43 100%)",
    controlColor: "rgba(255, 255, 255, 1)",
    codeColor: "519EF6",
    codeGroupColor: "B3B3B3",
    codeRecommendedColor: "55B45E",
  },
  {
    name: "white",
    theme: "light",
    label: "Белый",
    accentColor: "8C8C8C",
    backgroundColor: "FCFCFC",
    borderColor: "BBBBBB",
    color: "000000",
    control: "linear-gradient(180deg, #F0F0F0 0%, #DFDFDF 100%)",
    controlColor: "rgba(0, 0, 0, 0.6)",
  },
]

schemes.forEach((s: any) => {
  s.messageTextColor = "4F4F4F"
  s.innerBorderColor = "0000001a"
  s.innerBorderColorLight = "0000000f"

  s.textColorLight = "FFFFFF"

  // greyscale
  for (let i = 0; i < 11; i++) s["greyscale-" + i] = `hsl(0, 0%, ${i * 10}%)`

  // служебные кнопки панели редактора
  // цвет иконки
  s.editorControlColor = "hsl(0, 0%, 30%)"

  // фон кнопки = фон панели с небольшим SL-сдвигом
  // const bgHSL = hex2hsl(s.backgroundColor)
  // s.editorControlBgColor = bgHSL.shift({ s: -10, l: -5 })

  // s.editorFooterBgColor = bgHSL.shift({ h: 2, l: 2 })
  // s.editorFooterColor = "hsl(0, 0%, 50%)"

  // s.supplyBgColor = bgHSL.shift({ s: -bgHSL.s / 2, l: -3 })
  // s.supplyBgColorDarker = s.supplyBgColor.shift({ s: -7, l: -7 })
  // s.supplyBgColorGhost = bgHSL.shift({ s: -bgHSL.s * 0.89, l: -bgHSL.l * 0.21 })
  // s.supplyBgColorHover = bgHSL.shift({ s: -bgHSL.s * 0.37, l: -bgHSL.l * 0.08 })
  // s.lightAccentColor = bgHSL.shift({ s: -bgHSL.s * 0.1, l: -bgHSL.l * 0.32 })

  // // чат
  // s.messageColor = bgHSL.shift({ h: -1, s: -bgHSL.s * 0.26 })
  // s.messageSentColor = bgHSL.shift({ h: -1, s: -bgHSL.s * 0.31, l: -bgHSL.l * 0.1 })
  // s.messageHighlightColor = bgHSL.shift({ h: -1, s: -bgHSL.s * 0.07, l: -bgHSL.l * 0.17 })

  // // коды
  // const codeColors: { [key: string]: any } = {
  //   codeColor: "hsl(0, 0%, 20%)",
  //   codeColorHighlight: "#0069E4",
  //   codeBgColor: bgHSL.shift({ h: 4, s: -bgHSL.s * 0.2, l: -3 }),
  //   codeBorderColor: bgHSL.shift({ s: -bgHSL.s * 0.75, l: -15 }),
  //   codeGroupColor: "848484",
  //   codeRecommendedColor: "497041",
  // }
  // for (const k in codeColors) if (!s[k]) s[k] = codeColors[k]
})

// export const fonts = ['Arimo', 'EB Garamond', 'PT Astra Serif', 'PT Astra Sans', 'Roboto', 'Lato']
export const fonts = ["PT Astra Serif", "PT Astra Sans"]
export const lineHeights = [120, 135, 150]

export const panelScaleFactors = {
  common: { default: 1, wide: 2 },
  newsItem: { default: 1.32, wide: 3.32 },
}

export default {
  colors: colorsList,
  panelWidth,
  schemes,
  fonts,
  lineHeights,
  panelScaleFactors,
}
