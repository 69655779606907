import { computed } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { CodesStore } from "@/ContextApp/stores/codes"

export default function ({
  lang = "RU",
  omit,
}: {
  lang: string | undefined
  omit?: (number | string)[]
}) {
  const store = useStore<CodesStore>("codes", {
    shared: true,
  })

  const itemsById = computed(() => {
    const codes =
      lang === "RU"
        ? store.data.codesAvailableForRepublicationById?.ru
        : store.data.codesAvailableForRepublicationById?.en

    if (!omit?.length) {
      return codes
    }
    if (codes) {
      const res = Object.keys(codes)
        .filter(codeId => !omit.includes(Number(codeId)))
        .reduce((acc, id) => {
          const code = codes[id]
          acc[id] = code
          return acc
        }, {} as any)

      return res
    }
    return {}
  })

  // TODO: Потенциально вызывает большое потребление памяти
  const itemsByUid = computed(() => {
    const codes =
      lang === "RU"
        ? store.data.codesAvailableForRepublicationByUid?.ru
        : store.data.codesAvailableForRepublicationByUid?.en

    if (!omit?.length) {
      return codes
    }
    if (codes) {
      const res = Object.keys(codes)
        .filter(uid => codes[uid] && !omit.includes(Number(codes[uid].id)))
        .reduce((acc, uid) => {
          const code = codes[uid]
          acc[uid] = code
          return acc
        }, {} as any)

      return res
    }
    return {}
  })
  return { itemsById, items: itemsByUid }
}
