import { computed } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { GroupsAndUsersStore } from "@/ContextApp/stores/groupsAndUsers"
import type { AuthorsItemsMap } from "@/ContextTab/modules/news/authors/types"

export default function useGroupAndUsers() {
  const store = useStore<GroupsAndUsersStore>("groupsAndUsers", {
    shared: true,
  })

  const groupAndUsersById = computed<AuthorsItemsMap>(() => store.data.groupAndUsersById)
  const groupAndUsersByUid = computed<AuthorsItemsMap>(() => store.data.groupAndUsersByUid)

  const groupsAndNonBlockedUsersByUid = computed(() => {
    const filtered: Record<string, any> = {}
    Object.entries(groupAndUsersByUid.value ?? {}).forEach(([uid, item]) => {
      if (item.isUser && item.is_blocked) return
      filtered[uid] = item
    })
    return filtered
  })

  return {
    groupAndUsersById,
    groupAndUsersByUid,
    groupsAndNonBlockedUsersByUid,
  }
}
