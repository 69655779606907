<script setup lang="ts">
import format from "date-fns/format"
import formatDistanceToNow from "date-fns/formatDistanceToNow"
import isThisMonth from "date-fns/isThisMonth"
import isThisYear from "date-fns/isThisYear"
import isToday from "date-fns/isToday"
import ru from "date-fns/locale/ru"
import { computed, onBeforeUnmount, getCurrentInstance } from "vue"
import globalTimer from "@/ContextTab/services/globalTimer"
import formatToMsk from "@/utils/formatToMsk"
import { DateMode } from "./types"

const props = withDefaults(
  defineProps<{
    date: string | number | Date
    mode?: DateMode
    onlyTime?: boolean
    moscowTime?: boolean
    clickable?: boolean
    highlightColor?: string
  }>(),
  {
    mode: DateMode.Regular,
    onlyTime: false,
    moscowTime: false,
    clickable: false,
    highlightColor: "inherit",
  }
)

const emit = defineEmits(["dateModeChanged"])

const localDate = computed(() => new Date(props.date))

const formattedDate = computed(() => {
  const formatFunc = props.moscowTime ? formatToMsk : format
  return props.mode === DateMode.Regular
    ? formatFunc(localDate.value, makeFormatString(localDate.value), {
        locale: ru,
      }) ?? ""
    : formatDistanceToNow(localDate.value, { locale: ru, addSuffix: true })
})

const htmlString = computed(() => {
  if (!/\//.test(formattedDate.value)) return formattedDate.value
  const parts = formattedDate.value.split("/")
  return `<span>${parts[0]}</span><span>${parts[1]}</span>`
})

const makeFormatString = (date: Date) => {
  if (props.onlyTime) return "/HH:mm:ss"
  let format = ""
  if (!isThisMonth(date) || !isToday(date)) format += "d MMMM "
  if (!isThisYear(date)) format += "yyyy "
  format += "/HH:mm:ss"
  return format
}

const toggleMode = (event: MouseEvent) => {
  if (props.clickable) {
    event.stopPropagation()
    event.preventDefault()
    emit("dateModeChanged", Math.abs(props.mode - 1))
  }
}

const updateDistance = () => {
  if (props.mode === DateMode.Distance) {
    const instance = getCurrentInstance()
    instance?.proxy?.$forceUpdate()
  }
}

globalTimer.registerListener(updateDistance)
onBeforeUnmount(() => {
  globalTimer.unregisterListener(updateDistance)
})
</script>

<template>
  <span
    class="date-widget"
    :class="{ '-clickable': clickable }"
    @click="toggleMode"
    @dblclick.stop
    v-html="htmlString"
  ></span>
</template>

<style lang="postcss" scoped>
.date-widget {
  color: v-bind(highlightColor);

  &.-clickable {
    cursor: pointer;
  }
}
</style>
