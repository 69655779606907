<script setup lang="ts">
import PsButtonToolbar from "./PsButtonToolbar.vue"
import PsResponsiveButton from "./PsResponsiveButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import { useSlots } from "vue"
import pluralize from "@/utils/pluralize"
import type { Size } from "@/ContextTab/components/UI/types"

withDefaults(
  defineProps<{
    name?: string
    tooltip?: string
    showTooltip?: string[] | "always"
    count?: number
    plural?: string[]
    showClear?: boolean
    size?: Size
    disabled?: boolean
  }>(),
  {
    name: undefined,
    tooltip: "",
    showTooltip: () => ["sm"],
    count: 0,
    plural: undefined,
    showClear: false,
    size: "sm",
    disabled: false,
  }
)

const emit = defineEmits(["click", "clear"])

defineSlots<{
  xs(): any
  sm(): any
  md(): any
  lg(): any
  xl(): any
  xxl(): any
  xxxl(): any
  default(): any
  "prefix"(): any
  "suffix"(): any
}>()

const slots = useSlots()
</script>

<template>
  <PsResponsiveButton
    :tooltip="tooltip"
    :buttonComp="PsButtonToolbar"
    :disabled="disabled"
    :showTooltip="showTooltip"
    :contentStyle="{ gap: '0.25rem' }"
    tooltipPlacement="bottom"
    :size="size"
    @click="emit('click', $event)"
  >
    <template v-if="slots.default">
      <slot />
    </template>
    <template v-if="slots.prefix" #prefix>
      <slot name="prefix" />
    </template>
    <template v-if="slots.xs" #xs>
      <template v-if="count > 0">
        <span>
          {{ count }}
        </span>
      </template>
      <template v-else>
        <slot name="xs" />
      </template>
    </template>
    <template v-if="slots.sm" #sm>
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="sm" />
      </template>
    </template>
    <template v-if="slots.md" #md>
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="md" />
      </template>
    </template>
    <template v-if="slots.lg" #lg>
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="lg" />
      </template>
    </template>
    <template v-if="slots.xl" #xl>
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="xl" />
      </template>
    </template>
    <template v-if="slots.xxl" #xxl>
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="xxl" />
      </template>
    </template>
    <template v-if="slots.xxxl" #xxxl>
      <template v-if="count > 0">
        <span>
          {{ count }}
          <template v-if="plural">
            {{ pluralize(count.valueOf(), plural) }}
          </template>
        </span>
      </template>
      <template v-else>
        <slot name="xxxl" />
      </template>
    </template>
    <template #suffix>
      <slot name="suffix">
        <PbIcon
          v-if="showClear"
          class="clear-button"
          type="font"
          name="close"
          size="14"
          role="clear"
          @click.capture.stop.prevent="emit('clear')"
        />
      </slot>
    </template>
  </PsResponsiveButton>
</template>

<style scoped lang="postcss">
.clear-button {
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgb(0, 0, 0, 0.1);
  }
  cursor: pointer;
}
</style>
