<script setup lang="ts">
import { onMounted, nextTick, onUnmounted } from "vue"
import { useStoreAsync } from "@/ContextTab/useStore"
// eslint-disable-next-line import/no-extraneous-dependencies
import createLegacyApp from "@/lib/legacy/dist/publishing-ui.mjs"
// eslint-disable-next-line import/no-extraneous-dependencies
import "@/lib/legacy/dist/style.css"
import usePassAuth from "./composables/usePassAuth"
import usePassCodes from "./composables/usePassCodes"
import usePassContextId from "./composables/usePassContextId"
import usePassProfile from "./composables/usePassProfile"
import usePassRefs from "./composables/usePassRefs"
import usePassUsers from "./composables/usePassUsers"
import type { GatewayStore } from "@/ContextApp/stores/gateway"

let legacyApp: any = null

const passAuth = await usePassAuth()
const passProfile = await usePassProfile()
const passRefs = await usePassRefs()
const passCodes = await usePassCodes()
const passUsers = await usePassUsers()
const passContextId = await usePassContextId()

async function initLegacy() {
  const gatewayStore = await useStoreAsync<GatewayStore>("gateway", { shared: true })

  legacyApp = createLegacyApp(gatewayStore.data.sessionId)

  await passAuth(legacyApp)
  await passRefs(legacyApp)
  await passProfile(legacyApp)
  await passCodes(legacyApp)
  await passUsers(legacyApp)
  await passContextId(legacyApp)

  await nextTick()

  legacyApp.$mount("#legacy-app-wrapper")
}

onUnmounted(async () => {
  if (legacyApp !== null) {
    legacyApp.$store.commit("auth/UNMOUNT")
    await legacyApp.$store.dispatch('auth/reset')
    await legacyApp.$store.dispatch('userProfile/clearProfile')
    legacyApp.$destroy()
    legacyApp = null
  }
})

onMounted(() => {
  initLegacy()
})
</script>

<template>
  <div id="legacy-app">
    <div id="legacy-app-wrapper"></div>
  </div>
</template>
