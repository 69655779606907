<template>
  <div class="ps-main-nav _background:dark-shade-150">
    <div class="btn-group group-main">
      <slot name="group-main"></slot>
    </div>

    <div class="btn-group group-secondary">
      <slot name="group-secondary"></slot>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.ps-main-nav {
  color: #8d8d8d;
  position: relative;

  --flex-dir: column;

  /* => flex-direction=row */
  display: flex;
  justify-content: space-between;
  flex-direction: var(--flex-dir);
  z-index: 1;
  flex-grow: 1;

  .btn-group {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: var(--flex-dir);
    flex-basis: max-content;
    gap: 0.4em;
  }

  @media (max-width: 570px) {
    --flex-dir: row;
  }
}
</style>
