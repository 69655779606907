<script setup lang="ts">
import PublishedListItem from "./list/PublishedListItem.vue"
import PublishedListItemCompact from "./list/PublishedListItemCompact.vue"
import PublishedToolbar from "./toolbar/PublishedToolbar.vue"
import PanelList from "@/ContextTab/components/List/PanelList.vue"
import Panel from "@/ContextTab/components/Panel/Panel.vue"
import PsSearchField from "@/ContextTab/components/SearchField/PsSearchField.vue"
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import UnreadRibbon from "@/ContextTab/components/UnreadRibbon.vue"
import PublishedTitlebar from "@/ContextTab/modules/news/published/components/titlebar/PublishedTitlebar.vue"
import { IIcon } from "@inkline/inkline"
import { computed, ref, watch } from "vue"
import { MAX_SIZE_HEAD_SET } from "@/ContextApp/stores/news/published/set"
import { useStore } from "@/ContextTab/useStore"
import type { CodesStore } from "@/ContextApp/stores/codes"
import type { PublishedLocalFiltersStore } from "@/ContextApp/stores/news/published/localFilters"

const props = withDefaults(
  defineProps<{
    countUnreadNews: number
    dates: any
    errors?: any[] | null
    filters: any
    hasMoreItems?: boolean
    isFetching?: boolean
    items: any[]
    mode: 1 | 2
    preset: any
    selectedItemId: number | string
    searchMode?: boolean
    showSearch?: boolean
  }>(),
  {
    errors: null,
    hasMoreItems: true,
    isFetching: false,
    searchMode: false,
    showSearch: false,
  }
)
const emit = defineEmits([
  "applyPreset",
  "clear",
  "clearPreset",
  "clickUnread",
  "createPreset",
  "extend",
  "onOpenActionPopover",
  "reachedEnd",
  "reachedStart",
  "reload",
  "repeat",
  "republish",
  "resetFilters",
  "savePreset",
  "select",
  "toggleTree",
  "updateFilters",
  "search",
  "clearSearch",
  "update:showSearch",
])

const codesStore = useStore<CodesStore>("codes", {
  shared: true,
})

const localFilterStore = useStore<PublishedLocalFiltersStore>("publishedLocalFilters")

const searchQuery = computed(() => localFilterStore?.data?.searchQuery ?? "")

const updateSearchQuery = (query: string) => {
  if (localFilterStore.updateSearchQuery) {
    localFilterStore.updateSearchQuery(query)
  }
}

const toggleSearch = async () => {
  emit("update:showSearch", !props.showSearch)
}

const search = query => {
  if (query) {
    emit("search", query)
  }
}

const clearSearch = async () => {
  emit("clearSearch")

  if (searchQuery.value) {
    updateSearchQuery("")
  }
  if (props.searchMode) {
    toggleSearch()
  }
}

const ListItemComponent = computed(() =>
  props.mode === 1 ? PublishedListItem : PublishedListItemCompact
)
const list = ref<InstanceType<typeof PanelList> | null>(null)

const initialSize = computed(() => (props.mode === 1 ? 100 : 27))

watch(
  () => props.mode,
  (next, prev) => {
    if (next !== prev) {
      scrollToTop()
    }
  }
)

const allCodeItems = computed(() => codesStore.data.actualCodes)
const allCodeItemsByUid = computed(() => codesStore.data.allActualCodesByUid)

const getIsFeedCode = (code: any) => {
  return (
    (codesStore.created.value &&
      codesStore.data.feedCodeIds.includes(code.id.toString())) ??
    false
  )
}

const getIsFeedAndPubCode = (code: any) => {
  return (
    (codesStore.created.value &&
      codesStore.data.feedAndPubIds.includes(code.id.toString())) ??
    false
  )
}

const scrollToTop = () => {
  list.value?.scrollToTop()
}

const getScrollTopSize = () => list.value?.getScrollTopSize()

defineExpose({
  scrollToTop,
  getScrollTopSize,
})
</script>

<template>
  <Panel
    background="white"
    accent="#0069e4"
    class="published-panel"
    :showExtrabar="showSearch"
  >
    <template #titlebar>
      <PublishedTitlebar
        :dates="dates"
        :filters="filters"
        @reload="emit('reload', $event)"
        @toggleSearch="toggleSearch"
      />
    </template>
    <template #toolbar>
      <PublishedToolbar
        :filters="filters"
        :dates="dates"
        :panelMode="mode"
        :preset="preset"
        :showSearch="showSearch"
        @updateFilters="emit('updateFilters', $event)"
        @savePreset="emit('savePreset', $event)"
        @applyPreset="emit('applyPreset', $event)"
        @createPreset="emit('createPreset', $event)"
        @clear="emit('clear', $event)"
        @resetFilters="emit('resetFilters')"
        @clearPreset="emit('clearPreset')"
      />
    </template>
    <template #extrabar>
      <PsSearchField
        :initialQuery="searchQuery"
        :autofocus="true"
        color="light"
        placeholder="Поиск по выпущенным новостям"
        class="published-search"
        @input="updateSearchQuery"
        @submit="search"
        @clear="clearSearch"
      >
        <template v-if="searchMode && !searchQuery" #suffix>
          <PsTooltip content="Сбросить результаты поиска" placement="top">
            <IIcon
              name="ink-clear"
              class="published-search__clear"
              size="md"
              @click="clearSearch"
            />
          </PsTooltip>
        </template>
      </PsSearchField>
    </template>
    <template #content>
      <UnreadRibbon
        v-if="!searchMode"
        class="published-unread-ribbon"
        :class="{ low: showSearch }"
        :counter="countUnreadNews"
        :max="MAX_SIZE_HEAD_SET - 1"
        @click="emit('clickUnread')"
      />
      <PanelList
        v-if="codesStore.created"
        ref="list"
        :items="items"
        :initialSize="initialSize"
        :selectedItemId="selectedItemId"
        :errors="errors"
        :hasMoreItems="hasMoreItems"
        :isFetching="isFetching"
        @select="emit('select', $event)"
        @reachedEnd="emit('reachedEnd', $event)"
        @reachedStart="emit('reachedStart', $event)"
      >
        <template #request-error>
          <PsButton @click="emit('repeat')">Повторить запрос</PsButton>
          <PsButton v-if="searchMode" @click="clearSearch">
            Сбросить поиск
          </PsButton>
          <PsButton v-else @click="emit('resetFilters')">
            Сбросить фильтры
          </PsButton>
        </template>
        <template #empty-actions>
          <PsButton v-if="searchMode" @click="clearSearch">
            Сбросить поиск
          </PsButton>
          <PsButton v-else @click="emit('resetFilters')">
            Сбросить фильтры
          </PsButton>
        </template>
        <template #default="{ item, index, isSelected }">
          <ListItemComponent
            :item="item"
            :index="index"
            :isSelected="isSelected"
            :getIsFeedCode="getIsFeedCode"
            :getIsFeedAndPubCode="getIsFeedAndPubCode"
            :allCodeItems="allCodeItems"
            :allCodeItemsByUid="allCodeItemsByUid"
            :searchMode="searchMode"
            @republish="emit('republish', item.id)"
            @extend="emit('extend', item.id)"
            @onOpenActionPopover="emit('onOpenActionPopover')"
          />
        </template>
      </PanelList>
    </template>
  </Panel>
</template>

<style lang="postcss" scoped>
.published-panel {
  --toolbar-shadow: hsla(212, 100%, 45%, 0.4) 0px 4px 6px 0px,
    hsla(212, 100%, 45%, 0.2) 0px 6px 8px,
    hsla(212, 100%, 45%, 0.12) 0px 10px 10px;
}
.published-unread-ribbon {
  position: absolute;
  left: 50%;
  top: 6.25rem;
  transform: translate(-50%, 0);
  z-index: 1;

  &.low {
    top: 8.5rem;
  }
}

.published-search {
  --input--light--prefix-suffix--color: var(--color-gray-400);

  & .published-search__clear {
    cursor: pointer;
  }
}
</style>
