import { messageTypes } from "@/lib/messageHub/messages"
import messageHub from "./messageHubClient"

type Listener = () => void

class GlobalTimerService {
  private _listeners: Array<Listener>

  constructor() {
    this._listeners = []
    messageHub.addMessageListener({
      metaFilter: meta =>
        messageTypes.byId(meta.typeId).name === messageTypes.names.service &&
        meta.signal === "timer",
      callback: () => {
        this._listeners.forEach(func =>
          globalThis.requestIdleCallback(() => func())
        )
      },
    })
  }

  registerListener(listener: Listener) {
    this._listeners.push(listener)
  }

  unregisterListener(listener: Listener) {
    this._listeners = this._listeners.filter(func => func === listener)
  }
}

export default new GlobalTimerService()
