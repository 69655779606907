import { useElementSize } from "@vueuse/core"
import { computed, type Ref } from "vue"
import type { SizeBracket, SizeList } from "@/lib/types"

export const DEFAULT_BREAKPOINTS: SizeList = [
  [0, "xs"],
  [270, "sm"],
  [790, "md"],
  [920, "lg"],
  [1000, "xl"],
  [1300, "xxl"],
  [1800, "xxxl"],
]

export default function (
  toolbar: Ref<HTMLElement | null>,
  breakpoints?: Ref<SizeList | undefined>
) {
  const { width, height } = useElementSize(toolbar)

  const sizes = computed(() => breakpoints?.value ?? DEFAULT_BREAKPOINTS)

  const toolbarSize = computed(() => {
    let size: SizeBracket = "xs"
    for (const bp of sizes.value) {
      if (width.value >= bp[0]) {
        size = bp[1]
      }
    }
    return size
  })

  return {
    height,
    width,
    toolbarSize,
  }
}
