import intlFormat from "date-fns/intlFormat"

export type FormatOptions = {
  localeMatcher?: "lookup" | "best fit"
  weekday?: "narrow" | "short" | "long"
  era?: "narrow" | "short" | "long"
  year?: "numeric" | "2-digit"
  month?: "numeric" | "2-digit" | "narrow" | "short" | "long"
  day?: "numeric" | "2-digit"
  hour?: "numeric" | "2-digit"
  minute?: "numeric" | "2-digit"
  second?: "numeric" | "2-digit"
  timeZoneName?: "short" | "long"
  formatMatcher?: "basic" | "best fit"
  hour12?: boolean
  timeZone?: string
}

const defaultFormatOptions: FormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  timeZone: "Europe/Moscow",
  minute: "numeric",
  second: "numeric",
}

/**
 * Конвертирует дату в часовой пояс по Москве
 *
 * @param {Date|string|number} date Дата в локальном часовом поясе
 * @param {FormatOptions} formatOptions Опции форматирования даты
 * @returns {string|null} Дата по Москве в соответствии с опциями форматирования
 */
export default function (
  date: Date | string | number,
  formatOptions: FormatOptions = {}
): string | null {
  if (!date) return null

  return intlFormat(
    new Date(date),
    { ...defaultFormatOptions, ...formatOptions },
    { locale: "ru" }
  )
}
