<script setup lang="ts">
import PsPopover from "@/ContextTab/components/UI/Popover/PsPopover.vue"
import { computed } from "vue"
import type { CodeFilterTypes } from "@/ContextTab/modules/news/codes/types"

const props = withDefaults(
  defineProps<{
    codeIds?: []
    allCodeItems?: any
    allCodeItemsByUid?: any
    codeColors?: any
    codeType?: CodeFilterTypes
    maxItems?: number
    isCompact?: boolean
  }>(),
  {
    codeIds: () => [],
    allCodeItems: () => ({}),
    allCodeItemsByUid: () => ({}),
    codeColors: () => ({}),
    codeType: "feeds",
    maxItems: 3,
    isCompact: false,
  }
)

const isFeedsIndicator = computed(() => props.codeType === "feeds")
const visibleCodeIds = computed(() => props.codeIds.slice(0, props.maxItems))

const codeColor = id => {
  if (props.codeColors[id]) {
    return props.codeColors[id].color
  }

  if (!props.allCodeItems[id]) return ""

  const parentColor = props.allCodeItems[id].track.reduce((acc, curr) => {
    if (acc) return acc
    const colorId = props.allCodeItemsByUid[curr]?.id
    return props.codeColors[colorId]?.color || null
  }, null)

  return parentColor
}

const codeName = id => {
  if (!props.allCodeItems[id]) return ""
  const code = props.allCodeItems[id]
  if (code) return code.name
}
</script>

<template>
  <div
    class="color-bullets-indicator"
    :class="[
      `color-bullets-indicator_${codeType}`,
      `${
        isCompact
          ? 'list-item-compact__indicator'
          : 'list-item-non-compact__indicator'
      }`,
    ]"
  >
    <PsPopover placement="right" trigger="hover">
      <ul :class="`color-bullets-indicator__list_${codeType}`">
        <li
          v-for="id in visibleCodeIds"
          :key="id"
          class="color-bullets-indicator__item"
          :style="{ background: codeColor(id) }"
        ></li>
        <li
          v-if="
            (!isFeedsIndicator || isCompact) &&
            visibleCodeIds.length < codeIds.length
          "
          class="color-bullets-indicator__more"
        >
          +{{ codeIds.length - visibleCodeIds.length }}
        </li>
      </ul>
      <template #body>
        <div
          class="color-bullets-indicator__details"
          :class="{ [`color-bullets-indicator__details_${codeType}`]: true }"
        >
          <ul class="color-bullets-indicator__details-list">
            <li
              v-for="id in codeIds"
              :key="id"
              class="color-bullets-indicator__list-item"
            >
              <span
                class="color-bullets-indicator__item"
                :style="{ background: codeColor(id) }"
              ></span>
              <span class="color-bullets-indicator__name">{{
                codeName(id)
              }}</span>
            </li>
          </ul>
        </div>
      </template>
    </PsPopover>
  </div>
</template>

<style lang="postcss" scoped>
:deep(.pskit__popover) {
  height: 100%;
}
:deep(.pskit__popover__trigger) {
  height: 100%;
}
.color-bullets-indicator_feeds {
  .color-bullets-indicator__item {
    display: inline-block;
    width: 5px;
    flex-shrink: 0;
    margin: 0;
  }
}
.color-bullets-indicator_non-feeds {
  .color-bullets-indicator__item {
    flex-shrink: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

.list-item-compact__indicator {
  &.color-bullets-indicator {
    height: 100%;
    display: flex;
  }
  & .color-bullets-indicator__list_feeds {
    display: flex;
    align-items: center;
    height: 100%;
    flex-shrink: 0;

    .color-bullets-indicator__item {
      margin: 0;
      height: 100%;
    }

    .color-bullets-indicator__more {
      margin: 0;
      margin-left: 3px;
    }
  }
}

.list-item-non-compact__indicator {
  &.color-bullets-indicator_feeds {
    position: absolute;
    z-index: 20;
    top: 0;
    left: 0;
    height: 100%;
  }
  &.color-bullets-indicator_feeds .color-bullets-indicator__item {
    flex: 1;
  }
  & .color-bullets-indicator__list_feeds {
    display: flex;
    height: 100%;
    flex-direction: column;
  }
  & .color-bullets-indicator__item {
    flex: 1;
  }
}
.color-bullets-indicator__list_non-feeds {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 3px;
  padding-bottom: 1px;
}
.color-bullets-indicator__details_feeds {
  .color-bullets-indicator__item {
    display: inline-block;
    width: 5px;
    flex-shrink: 0;
  }
}
.color-bullets-indicator__details_non-feeds {
  .color-bullets-indicator__item {
    flex-shrink: 0;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}

.color-bullets-indicator__more {
  font-size: 13px;
  font-weight: bold;
  color: #9d9d9d;
}

.color-bullets-indicator__details {
  font-size: 15px;
  max-width: 220px;
}
.color-bullets-indicator__details-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.color-bullets-indicator__list-item {
  display: flex;
  gap: 8px;
}
.color-bullets-indicator__details_non-feeds {
  .color-bullets-indicator__list-item {
    align-items: center;
  }
}
</style>
