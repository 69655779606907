export const filterPrivate = (store: any) =>
  Object.entries(store)
    .filter(([key, value]) => !key.startsWith("_") && !key.startsWith("$"))
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1]
      return acc
    }, {} as any)

export const filterFunctions = (store: any) =>
  Object.entries(store)
    .filter(([_, value]) => typeof value === "function")
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1]
      return acc
    }, {} as any)

export const omitFunctions = (store: any) =>
  Object.entries(store)
    .filter(([_, value]) => typeof value !== "function")
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1]
      return acc
    }, {} as any)
