import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import { mount } from "mount-vue-component"
import { h } from "vue"

let key = 0

export const markupEntities = (
  contentainer: HTMLElement | null,
  version: any,
  searchMode: boolean
) => {
  if (!contentainer || !version.entities) return

  const entities = version.entities.map((item: any) => item.entity)
  const entityNodes: NodeListOf<HTMLElement> = contentainer.querySelectorAll(
    "span[data-entity-id]"
  )

  entityNodes.forEach(node => {
    const id = parseInt(node.getAttribute("data-entity-id") ?? "", 10)
    const entity = entities.find((entity: any) => entity.id === id)
    if (!entity) return

    const text = node.innerText
    node.innerText = ""

    mount(PsTooltip, {
      props: {
        content: `${Object.values(entity.properties).map(
          value => `${value} — `
        )}${entity.name}`,
        key: key++,
      },
      children: () => [
        h(
          "span",
          {
            class: "_text-color:primary",
          },
          // при поиске по сущностям внутрь добавляется элемент <em> для того, чтобы выделить поисковый запрос
          searchMode ? h("em", text) : text
        ),
      ],
      element: node,
    })
  })
}
