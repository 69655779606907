import differenceInMilliseconds from "date-fns/differenceInMilliseconds"
import isSameDay from "date-fns/isSameDay"
import parseISO from "date-fns/parseISO"
import startOfTomorrow from "date-fns/startOfTomorrow"
import { onMounted, ref, type Ref } from "vue"
import type { NewsItemPublished } from "@/ContextTab/modules/news/published/components/types"

/**
 * Содержит логику отображения дата-разделителей.
 *
 * Выставляет таймаут таску на обновление текущей даты.
 */
export default function (items: Ref<NewsItemPublished[]>) {
  const currentDate = ref(Date.now())

  function setDayChangeTimer() {
    setTimeout(
      () => {
        currentDate.value = Date.now()
        setDayChangeTimer()
      },
      differenceInMilliseconds(startOfTomorrow(), currentDate.value)
    )
  }
  onMounted(() => {
    setDayChangeTimer()
  })

  function needDateDelimiter(index?: number) {
    if (index === undefined) return false

    const currentEntry = items.value[index]
    if (!currentEntry?.id) return false

    const prevEntry = items.value[index - 1]

    if (!prevEntry?.id) {
      return !isSameDay(
        parseISO(currentEntry.status_modified_at || ""),
        new Date()
      )
    }

    return !isSameDay(
      parseISO(prevEntry.status_modified_at || ""),
      parseISO(currentEntry.status_modified_at || "")
    )
    // return !isSameHour(
    //   parseISO(prevEntry.status_modified_at || ''),
    //   parseISO(currentEntry.status_modified_at || '')
    // )
  }

  return {
    currentDate,
    needDateDelimiter,
  }
}
