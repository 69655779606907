import { ref, type Ref } from "vue"
import { useStore } from "@/ContextTab/useStore"
import type { Message } from "@/ContextTab/components/UI/WithInplaceMessage/types"
import type { NewsStore } from "@/ContextApp/stores/news"

export default function useNewsActions(
  loading: Ref<boolean>,
  message: Ref<Message | null>
) {
  const store = useStore<NewsStore>("news", { shared: true })

  const action = async (func: any, id: string | number) => {
    if (store.created) {
      loading.value = true
      const result = await func(id)
      if (result.payload.legacy === false) {
        if (result?.payload?.response?.data?.id) {
          window.open(
            `${window.location.origin}/legacy/news/${result?.payload?.response?.data?.id}`
          )
        }
      }
      message.value = result.payload.message || null
      loading.value = false
    }
  }

  const extend = (id: string | number) => action(store.extend, id)
  const republish = (id: string | number) => action(store.republish, id)
  const revoke = (id: string | number) => action(store.revoke, id)
  const open = (id: string | number) => action(store.open, id)

  return {
    extend,
    republish,
    revoke,
    open,
  }
}
