<script setup lang="ts">
import PsFilterButton from "@/ContextTab/components/UI/Button/PsFilterButton.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import usePresetButton from "./composables/usePresetButton"

defineProps<{
  preset: any
  disabled: boolean
}>()

const emit = defineEmits(["click"])
const { size, bracket } = usePresetButton()
</script>

<template>
  <PsFilterButton
    :color="bracket === 'xs' ? 'default' : null"
    :disabled="!preset || disabled"
    :tooltip="
      preset
        ? `Сохранить фильтры в пресет ${preset.name}`
        : 'Выберите пресет, чтобы обновить'
    "
    tooltipPlacement="bottom"
    showTooltip="always"
    size="sm"
    @click="emit('click')"
  >
    <template #prefix>
      <PbIcon name="save" size="14" />
    </template>
    <template #xs>Сохранить</template>
    <template #sm></template>
    <template #xl>Сохранить</template>
  </PsFilterButton>
</template>
