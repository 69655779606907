import useAuthInfo from "@/ContextTab/modules/auth/composables/useAuthInfo"

const update = (el, binding, vnode) => {
  const { getIsUserHasAnyPermission, getIsUserHasAllPermissions } =
    useAuthInfo()
  if (binding.value?.length) {
    let isVisible = true
    if (binding.arg === "any") {
      isVisible = getIsUserHasAnyPermission(binding.value)
    } else {
      isVisible = getIsUserHasAllPermissions(binding.value)
    }
    if (isVisible === false) {
      el.style.display = "none"
    } else {
      el.style.display = ""
    }
  }
}

export default {
  name: "permissions",
  beforeMount: update,
}
