<script setup lang="ts">
import PsButton from "@/ContextTab/components/UI/Button/PsButton.vue"
import PsButtonGroup from "@/ContextTab/components/UI/Button/PsButtonGroup.vue"
import PsOneLineInput from "@/ContextTab/components/UI/Form/PsOneLineInput.vue"
import PsPopover from "@/ContextTab/components/UI/Popover/PsPopover.vue"
import PsTooltip from "@/ContextTab/components/UI/Popover/PsTooltip.vue"
import PbIcon from "@/ContextTab/components/UI/PsIcon.vue"
import PsTag from "@/ContextTab/components/UI/PsTag.vue"
import WithInplaceMessage from "@/ContextTab/components/UI/WithInplaceMessage/WithInplaceMessage.vue"
import { IListGroup, IListGroupItem } from "@inkline/inkline"
import { ref, computed, nextTick, reactive, watchEffect } from "vue"

const props = defineProps<{
  preset: any
  onRename?: (arg: any) => any
  onDelete?: (arg: any) => any
}>()

const emit = defineEmits(["apply"])

enum MenuStates {
  Closed,
  Menu,
  Rename,
  Delete,
  Apply,
  Busy,
  Result,
}

const errors = reactive([])

const menuShowed = ref(false)
const menuState = ref(MenuStates.Closed)
const applyFormOpen = ref(false)

const menuOpen = computed(() => menuState.value !== MenuStates.Closed)

const newName = ref(props.preset.name)
const renameInput = ref<HTMLElement | null>(null)

const busy = ref(false)

const options = [
  {
    label: "Переименовать",
    key: "rename",
  },
  {
    label: "Удалить",
    key: "delete",
  },
]

const onMenuAction = (action: string) => {
  switch (action) {
    case "rename":
      menuState.value = MenuStates.Rename
      nextTick().then(() => {
        if (renameInput.value) {
          renameInput.value.focus()
        }
      })
      break
    case "delete":
      menuState.value = MenuStates.Delete
      break

    // case "apply":
    //   menuState.value = MenuStates.Apply
    //   break
  }
  if (action) menuShowed.value = false
}

const renameDisabled = computed(() => {
  return (
    newName.value.trim().length === 0 ||
    newName.value.trim() === props.preset.name
  )
})

const onStateClick = (_: any, force = false) => {
  switch (menuState.value) {
    case MenuStates.Closed:
      menuState.value = MenuStates.Menu
      break
    case MenuStates.Menu:
      menuState.value = MenuStates.Closed
      break
    case MenuStates.Rename:
      if (force) {
        menuState.value = MenuStates.Closed
      }
      break
    case MenuStates.Delete:
    case MenuStates.Apply:
      menuState.value = MenuStates.Closed
      break
  }
}

const onActionEnd = () => {
  errors.length = 0
  busy.value = false
  onStateClick(null, true)
}

const rename = async (name: string) => {
  if (name === props?.preset?.name) return
  busy.value = true
  if (props.onRename) {
    const result = await props.onRename({
      id: props.preset.id,
      name,
    })
    if (result.errors) {
      Object.assign(errors, result?.errors)
      busy.value = false
    } else {
      onActionEnd()
    }
  }
}

const remove = async () => {
  if (props.onDelete) {
    errors.length = 0
    busy.value = true
    const result = await props.onDelete(props.preset.id)
    if (result.errors) {
      Object.assign(errors, result?.errors)
      busy.value = false
    } else {
      onActionEnd()
    }
  }
}

const apply = e => {
  emit("apply", props.preset.id)
  applyFormOpen.value = false
}

watchEffect(() => {
  if (!menuShowed.value || !menuOpen.value) {
    busy.value = false
    errors.length = 0
  }
})
</script>

<template>
  <PsTooltip :content="preset.name" :disabled="preset.name.length < 30">
    <PsTag
      :key="preset.id"
      size="lg"
      hoverColor="rgba(0,0,0,0.1)"
      class="preset"
      :suffixActive="menuOpen"
      @contentClick="apply"
      @suffixClick="onStateClick"
    >
      <span class="preset-name">{{ preset.name }}</span>
      <template #suffix>
        <PsPopover
          trigger="manual"
          :open="menuOpen"
          :arrow="true"
          placement="bottom"
          class="preset-menu-popover"
          @clickOutside="onStateClick"
        >
          <PbIcon name="dots" size="14" />
          <template #body>
            <IListGroup
              v-if="menuState === MenuStates.Menu"
              :border="false"
              size="sm"
              class="preset-action-item"
            >
              <IListGroupItem
                v-for="{ key, label } in options"
                :key="key"
                class="menu-item"
                @click="onMenuAction(key)"
              >
                {{ label }}
              </IListGroupItem>
            </IListGroup>
            <PsOneLineInput
              v-if="menuState === MenuStates.Rename"
              v-model="newName"
              :active="(newName.length && newName !== preset.name) || busy"
              :initialValue="preset.name"
              autoselect
              autofocus
              :disabled="busy"
              :busy="busy"
              name="name"
              :errors="errors"
              placeholder="Название пресета"
              :onApply="rename"
              @cancel="onActionEnd"
            />
            <template v-if="menuState === MenuStates.Delete">
              <WithInplaceMessage
                :loading="busy"
                :loaderElementsStyle="{
                  width: '22px',
                  height: '22px',
                  top: 'calc(50% - 11px)',
                  left: 'calc(50% - 11px)',
                }"
              >
                <div class="submit-form">
                  <span>Удалить пресет?</span>
                  <PsButtonGroup>
                    <PsButton :disabled="busy" @click="remove">
                      <PbIcon name="check-icon" color="green" size="14" />
                    </PsButton>
                    <PsButton :disabled="busy" @click="onActionEnd">
                      <PbIcon name="close" color="red" size="14" />
                    </PsButton>
                  </PsButtonGroup>
                  <!-- <ErrorList v-if="errors?.length" :errors="errors" /> -->
                </div>
              </WithInplaceMessage>
            </template>
          </template>
        </PsPopover>
      </template>
    </PsTag>
  </PsTooltip>
</template>

<style lang="postcss">
.preset-menu-popover {
  --popover--padding: 0;
}
</style>

<style scoped lang="postcss">
.menu-item {
  --list-group--border-bottom-width: 0 !important;
  cursor: pointer;

  &:hover {
    background-color: var(--color-light-shade-50);
  }
}
.preset {
  --badge--font-weight: normal;

  .preset-name {
    display: inline-block;
    max-width: 30ch;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.submit-form {
  align-items: center;
  flex-wrap: wrap;

  > span {
    font-size: 1rem;
    font-weight: bold;
    display: inline-flex;
    padding: 0 0.5em;
  }
}
</style>
