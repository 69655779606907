<script setup lang="ts">
import Popover from "@/ContextTab/components/UI/Popover/PsPopover.vue"
import { computed, ref, watch } from "vue"
import type { SizeBracket } from "@/lib/types"

const props = withDefaults(
  defineProps<{
    bracket: string
    collapseOn?: SizeBracket
  }>(),
  {
    collapseOn: "xs",
  }
)

const model = defineModel<boolean>({ default: false })

const trigger = ref<HTMLElement | null>(null)

const isCollapsed = computed(() => {
  const sizes = ["xs", "sm", "md", "lg", "xl", "xxl", "xxxl"]

  const collapseOnIndex = sizes.findIndex(s => s === props.collapseOn)
  const bracketIndex = sizes.findIndex(s => s === props.bracket)
  return bracketIndex <= collapseOnIndex
})

const hidePopover = () => {
  model.value = false
}

watch(
  () => props.bracket,
  (next, prev) => {
    if (next !== prev && prev === "xs") {
      model.value = false
    }
  }
)

const onTriggerClick = () => {
  if (model.value) {
    model.value = false
  } else {
    model.value = true
  }
}
</script>

<template>
  <Popover
    v-if="isCollapsed"
    trigger="manual"
    :open="model"
    placement="bottom"
    class="action-popover"
    @clickOutside="hidePopover"
    @clickTrigger="onTriggerClick"
  >
    <slot name="trigger" />
    <template #body>
      <div class="buttons">
        <slot />
      </div>
    </template>
  </Popover>
  <template v-else>
    <slot />
  </template>
</template>

<style scoped lang="postcss">
.buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5em;
}
</style>
